import { MasterDataFilter } from "models/MasterData";
import { PermissionContent } from "models/PermissionContent";
import { Moment } from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Model } from "react3l-common";
import { Select, TreeSelect } from "react3l-ui-library";
import { roleRepository } from "repositories/role-repository";

export interface IdFilterInputProps {
  value?: string | number | Moment | boolean | undefined | Model;
  index?: number;
  contents?: PermissionContent[];
  setContents?: (v: PermissionContent[]) => void;
  disabled?: boolean;
  searchBy?: string;
  resetValue?: boolean;
  setResetValue?: (dt: boolean) => void;
}

function GeneralFieldIdFieldInput(props: IdFilterInputProps) {
  const [translate] = useTranslation();

  const { index, contents, setContents, disabled, resetValue, setResetValue } =
    props;
  const [object, setObject] = React.useState<Model>(new Model());
  const [load, setLoad] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (
      (load && contents[index]?.masterEntityId && contents[index].masterData) ||
      resetValue
    ) {
      setObject(contents[index].masterData);
      setLoad(false);
      setResetValue(false);
    }
  }, [contents, index, load, object, resetValue, setResetValue]);

  const handleChange = React.useCallback(
    (value, valueObject) => {
      if (value?.length > 0) {
        setObject(value[0]);
        contents[index] = {
          ...contents[index],
          value: value[0]?.entityId,
          masterData: value[0],
        };
      } else {
        setObject(valueObject);
        contents[index] = {
          ...contents[index],
          value: valueObject?.entityId,
          masterData: valueObject,
        };
      }
      setContents([...contents]);
    },
    [contents, index, setContents]
  );

  const renderInput = React.useMemo(() => {
    return () => {
      if (contents[index]?.masterEntity?.isTree === 1) {
        return (
          <TreeSelect
            placeHolder={translate("permissions.placeholder.idField")}
            selectable={true}
            classFilter={MasterDataFilter}
            onChange={handleChange}
            checkStrictly={true}
            getTreeData={roleRepository.singleListMasterData}
            item={object}
            disabled={disabled}
            appendToBody
            valueFilter={{
              ...new MasterDataFilter(),
              masterEntityId: {
                equal: contents[index]?.masterEntityId,
              },
            }}
            type={0}
          />
        );
      } else
        return (
          <Select
            classFilter={MasterDataFilter}
            placeHolder={translate("permissions.placeholder.idField")}
            getList={roleRepository.singleListMasterData}
            onChange={handleChange}
            value={object}
            valueFilter={{
              ...new MasterDataFilter(),
              masterEntityId: {
                equal: contents[index]?.masterEntityId,
              },
            }}
            disabled={disabled}
            appendToBody
            type={0}
          />
        );
    };
  }, [contents, disabled, handleChange, index, object, translate]);

  return <>{renderInput()}</>;
}

export default GeneralFieldIdFieldInput;
