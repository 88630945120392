import { DiagramEditor } from "components/Diagram/codebase/diagramWithEditor";
import { WorkflowStep } from "models/WorkflowStep";
import React from "react";
import {
  detailService,
  ModelAction,
  ModelActionEnum,
} from "services/page-services/detail-service";
import { useTranslation } from "react-i18next";

export interface WorkflowStepDetailModalProps {
  visible: boolean;
  handleClose: () => void;
  handleSave: () => void;
  model: WorkflowStep;
  dispatch: React.Dispatch<ModelAction<WorkflowStep>>;
}

function useWorkflowStepDetailModal(
  editor: DiagramEditor,
  setInactiveSteps: React.Dispatch<React.SetStateAction<WorkflowStep[]>>
) {
  const [translate] = useTranslation();
  const [visibleModal, setVisibleModal] = React.useState(false);
  const { model, dispatch } =
    detailService.useModel<WorkflowStep>(WorkflowStep);

  const openModal = React.useCallback(
    (workflowStep?: any) => {
      if (workflowStep) {
        dispatch({ type: ModelActionEnum.SET, payload: workflowStep });
      }
      setVisibleModal(true);
    },
    [dispatch]
  );

  const closeModal = React.useCallback(() => {
    if (model.isNew) {
      editor?.diagram?.data?.remove(model.id);
    }
    setVisibleModal(false);
  }, [editor?.diagram?.data, model.id, model.isNew]);

  const saveModal = React.useCallback(() => {
    // validate here
    model.errors = {};
    if (!model.code) {
      model.errors.code = translate("general.errors.required");
    } else {
      model.errors.code = undefined;
    }
    if (!model.name) {
      model.errors.name = translate("general.errors.required");
    } else {
      model.errors.name = undefined;
    }
    if (!model.role) {
      model.errors.role = translate("general.errors.required");
    } else {
      model.errors.role = undefined;
    }
    if (model.errors.code || model.errors.name || model.errors.role) {
      dispatch({ type: ModelActionEnum.SET, payload: { ...model } });
      return;
    }

    if (model.isNew) {
      model.isNew = !model.isNew;
    }
    const step = editor?.diagram?.data?.find((x) => x.id === model.id);
    if (step.statusId === 1 && model.statusId === 0) {
      /** inactive */
      // remove step
      editor?.diagram?.data?.remove(step.id);

      // push to left panel
      setTimeout(
        () =>
          setInactiveSteps((origin) => {
            return [...origin, model];
          }),
        0
      );
    } else {
      editor?.diagram?.data?.update(model.id, model);
    }

    setVisibleModal(false);
  }, [dispatch, editor?.diagram?.data, model, setInactiveSteps, translate]);

  return {
    model,
    dispatch,
    visibleModal,
    openModal,
    closeModal,
    saveModal,
  };
}

export default useWorkflowStepDetailModal;
