import { Repository } from "react3l-common";
import { kebabCase } from "lodash";
import { httpConfig } from 'config/http';
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { AxiosResponse } from "axios";

import nameof from "ts-nameof.macro";

import { API_REGION_PREFIX } from "config/api-consts";
import { Region, RegionFilter } from 'models/Region';
import { Status, StatusFilter } from 'models/Status';

export type KeyType = string | number;

export class RegionRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.baseURL = new URL(API_REGION_PREFIX, BASE_API_URL).href;
    }

    public count = (regionFilter?: RegionFilter): Observable<number> => {
        return this.http.post<number>(kebabCase(nameof(this.count)), regionFilter)
            .pipe(Repository.responseDataMapper<number>());
    };

    public list = (regionFilter?: RegionFilter): Observable<Region[]> => {
        return this.http.post<Region[]>(kebabCase(nameof(this.list)), regionFilter)
            .pipe(Repository.responseMapToList<Region>(Region));
    };

    public get = (id: number | string): Observable<Region> => {
        return this.http.post<Region>
            (kebabCase(nameof(this.get)), { id })
            .pipe(Repository.responseMapToModel<Region>(Region));
    };

    public create = (region: Region): Observable<Region> => {
        return this.http.post<Region>(kebabCase(nameof(this.create)), region)
            .pipe(Repository.responseMapToModel<Region>(Region));
    };

    public update = (region: Region): Observable<Region> => {
        return this.http.post<Region>(kebabCase(nameof(this.update)), region)
            .pipe(Repository.responseMapToModel<Region>(Region));
    };

    public delete = (region: Region): Observable<Region> => {
        return this.http.post<Region>(kebabCase(nameof(this.delete)), region)
            .pipe(Repository.responseMapToModel<Region>(Region));
    };

    public bulkDelete = (idList: KeyType[]): Observable<void> => {
        return this.http.post(kebabCase(nameof(this.bulkDelete)), idList)
            .pipe(Repository.responseDataMapper());
    };

    public save = (region: Region): Observable<Region> => {
        return region.id ? this.update(region) : this.create(region);
    };

    public singleListStatus = (): Observable<Status[]> => {
        return this.http.post<Status[]>(kebabCase(nameof(this.singleListStatus)), new StatusFilter())
            .pipe(Repository.responseMapToList<Status>(Status));
    };

    public filterListStatus = (): Observable<Status[]> => {
        return this.http.post<Status[]>(kebabCase(nameof(this.filterListStatus)), new StatusFilter())
            .pipe(Repository.responseMapToList<Status>(Status));
    };

    public import = (file: File, name: string = nameof(file)): Observable<void> => {
        const formData: FormData = new FormData();
        formData.append(name, file as Blob);
        return this.http.post<void>(kebabCase(nameof(this.import)), formData)
            .pipe(Repository.responseDataMapper<any>());
    };

    public export = (filter: any): Observable<AxiosResponse<any>> => {
        return this.http.post('export', filter, {
            responseType: 'arraybuffer',
        });
    };

    public exportTemplate = (): Observable<AxiosResponse<any>> => {
        return this.http.post('export-template', {}, {
            responseType: 'arraybuffer',
        });
    };

}

export const regionRepository = new RegionRepository();
