import { Col, Row } from "antd";
import PageHeader from "components/PageHeader/PageHeader";
import { Button, Modal, StandardTable } from "react3l-ui-library";
import { useMenuReportPreviewHook } from "./MenuReportPreviewHook";
import Close16 from "@carbon/icons-react/es/close/16";
import Download16 from "@carbon/icons-react/es/download/16";
import { formatDateTime } from "helpers/date-time";
import { utilService } from "services/common-services/util-service";

export function MenuReportPreview() {
  const {
    translate,
    handleGoMaster,
    handleCloseTemplateInputModal,
    variablesFileDownloadPath,
    model,
    columns,
    columnFileTemplateInputs,
    visibleFileTemplateInputModal,
    currentFileTemplateInputs,
  } = useMenuReportPreviewHook();
  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("menuReports.master.subHeader")}
          breadcrumbItems={[
            translate("menuReports.master.header"),
            translate("menuReports.master.subHeader"),
          ]}
        />
        <div className="page page-detail p-t--lg p-l--xxl p-r--xxl p-b--lg">
          <div className="page-detail__title p-b--lg">
            {translate("general.detail.title")}
          </div>
          <Row>
            <Col span={6} className="p-r--sm">
              <div className="p-t--xxs">
                <div className="page-detail__title general-field__first-row">
                  <span>{translate("menuReports.site")}</span>
                </div>
                <div className="general-field__second-row p-t--xxs">
                  <span>{model?.site?.name}</span>
                </div>
              </div>
            </Col>
            <Col span={6} className="p-r--sm">
              <div className="p-t--xxs">
                <div className="page-detail__title general-field__first-row">
                  <span>{translate("menuReports.menu")}</span>
                </div>
                <div className="general-field__second-row p-t--xxs">
                  <span>{model?.menu?.name}</span>
                </div>
              </div>
            </Col>
            <Col span={6} className="p-r--sm">
              <div className="p-t--xxs">
                <div className="page-detail__title general-field__first-row">
                  <span>{translate("menuReports.name")}</span>
                </div>
                <div className="general-field__second-row p-t--xxs">
                  <span>{model?.name}</span>
                </div>
              </div>
            </Col>
            <Col span={6} className="p-r--sm p-t--sm">
              <Button
                type="outline-primary"
                className="btn--lg"
                icon={<Download16 />}
                onClick={() => {
                  utilService.downloadURI(
                    variablesFileDownloadPath,
                    "fileName"
                  );
                }}
              >
                {translate("menuReports.exportVariables")}
              </Button>
            </Col>
            <Col span={6} className="p-r--sm p-t--sm">
              <div className="p-t--xxs">
                <div className="page-detail__title general-field__first-row">
                  <span>{translate("menuReports.createdAt")}</span>
                </div>
                <div className="general-field__second-row p-t--xxs">
                  <span>{formatDateTime(model?.createdAt)}</span>
                </div>
              </div>
            </Col>
            <Col span={6} className="p-r--sm p-t--sm">
              <div className="p-t--xxs">
                <div className="page-detail__title general-field__first-row">
                  <span>{translate("menuReports.updatedAt")}</span>
                </div>
                <div className="general-field__second-row p-t--xxs">
                  <span>{formatDateTime(model?.updatedAt)}</span>
                </div>
              </div>
            </Col>
            <Col span={6} className="p-r--sm p-t--sm">
              <div className="p-t--xxs">
                <div className="page-detail__title general-field__first-row">
                  <span>{translate("menuReports.modifier")}</span>
                </div>
                <div className="general-field__second-row p-t--xxs">
                  <span>{model?.modifier?.displayName}</span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="m-b--lg m-t--sm">
            <StandardTable
              columns={columns}
              dataSource={model.fileTemplates}
              rowKey={"id"}
              pagination={false}
              tableSize="lg"
              isDragable={true}
              scroll={{ x: 1000, y: 500 }}
            />
          </div>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
      <Modal
        size={900}
        visibleFooter={false}
        visible={visibleFileTemplateInputModal}
        handleCancel={handleCloseTemplateInputModal}
        title={translate("fileTemplates.fileTemplateInputs.title")}
        titleButtonCancel={translate("general.actions.close")}
        titleButtonApply={translate("general.actions.save")}
      >
        <div className="modal-content">
          <div className="m-t--sm">
            <div className="w-100">
              <StandardTable
                rowKey={"id"}
                columns={columnFileTemplateInputs}
                dataSource={currentFileTemplateInputs}
                tableSize={"lg"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
