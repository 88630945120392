import {
  ICustomShapeConfig,
  IFlowShapeConfig,
  // IGroupConfig,
  ILineConfig,
  // ISwimlaneConfig,
} from "../codebase/types/ts-diagram/sources/types";
import { WorkflowDirection } from "models/WorkflowDirection";
import { WorkflowStep } from "models/WorkflowStep";
// import { Role } from "models/Role";
import { WorkflowDefinition } from "models/WorkflowDefinition";
import appConnectivity from "assets/images/app-connectivity.svg";
import { DiagramEditor } from "../codebase/diagramWithEditor";
import { v4 as uuidv4 } from "uuid";

export const workflowStepDefault = {
  width: 200,
  height: 90,
  icon: appConnectivity,
  preview: {
    scale: 1,
  },
};

// export const workflowStepTemplate = (config: ICustomShapeConfig) => `
//     <section class="workflow-step__card flex-row">
//       <div class="workflow-step__card__icon">
//         <img src="${config.icon}"></img>
//       </div>
//       <div class="workflow-step__card__description">
//         <span>${config.name}</span>
//         <span>${config.code}</span>
//         <span>${config.description}</span>
//         <span>${config?.role?.name ?? "role"}</span>
//       </div>
//     </section>
// `;

export const workflowStepTemplate = (config: ICustomShapeConfig) => `
    <section class="workflow-step__card flex-row">
      <div class="workflow-step__card__icon">
        <img src="${config.icon}"></img>
      </div>
      <div class="workflow-step__card__description">
        <span>${config?.name || "Tên"}</span>
        <span>${config?.code || "Mã"}</span>
        <span>${config?.role?.name || "Vai trò"}</span>
      </div>
    </section>
`;

export function convertModelDataToDiagramData(
  workflowDefinition: WorkflowDefinition
) {
  if (workflowDefinition.id) {
    const workflowRoles = workflowDefinition.workflowRoles;
    const workflowSteps = workflowDefinition.workflowSteps.filter(
      (x) => x.statusId === 1
    );
    const workflowDirections = workflowDefinition.workflowDirections;

    if (workflowRoles && workflowSteps && workflowDirections) {
      let diagramData: any[] = [];
      // diagramData = [
      //   ...diagramData,
      //   toSwimlane(workflowDefinition, workflowRoles),
      // ];
      // diagramData = [
      //   ...diagramData,
      //   ...workflowRoles.map((role) => toGroup(role)),
      // ];
      diagramData = [
        ...diagramData,
        ...workflowSteps.map((step) => toNode(step)),
      ];
      diagramData = [
        ...diagramData,
        ...workflowDirections.map((direction) => toLine(direction)),
      ];

      return diagramData;
    }
  }

  return;
}

export function leftPanelConvertData(workflowSteps: WorkflowStep[]): any[] {
  if (workflowSteps && workflowSteps.length > 0) {
    const nodes = workflowSteps.map((step) => {
      const node: IFlowShapeConfig = {
        ...step,
        stepId: step.id,
        type: "workflowStep",
        width: step.width,
        height: step.height,
        name: step.name,
        code: step.code,
        description: "description",
        preview: {
          scale: 1,
        },
      };
      node.secondId = node.id;
      node.id = undefined;
      node.$item = undefined;
      node.x = undefined;
      node.y = undefined;
      node.xCoordinate = undefined;
      node.yCoordinate = undefined;

      return node;
    });

    return nodes;
  }

  return [];
}

export function newStep(): any[] {
  return [
    {
      type: "workflowStep",
      statusId: 1,
      width: 200,
      height: 100,
      name: "Tên",
      code: "Mã",
      role: {
        name: "Vai trò",
      },
      description: "description",
      editable: true,
      preview: {
        scale: 1,
      },
    },
  ];
}

export function toNode(workflowStep: WorkflowStep): any {
  const node: IFlowShapeConfig = {
    ...workflowStep,
    workflowDefinitionId: workflowStep.workflowDefinitionId,
    type: "workflowStep",
    width: workflowStep.width,
    height: workflowStep.height,
    x: workflowStep.xCoordinate,
    y: workflowStep.yCoordinate,
    id: workflowStep.id.toString(),
    name: workflowStep.name,
    code: workflowStep.code,
    description: "description",
    isOld: true,
  };

  return node;
}

export function toLine(workflowDirection: WorkflowDirection): any {
  let conditions;
  if (workflowDirection.workflowDirectionConditions) {
    conditions = workflowDirection.workflowDirectionConditions.map(
      (condition) => {
        return {
          conditionId: condition.id,
          distance: condition.coordinate,
          text:
            condition.workflowParameter.name +
              " " +
              condition.workflowOperator.name +
              " " +
              condition?.masterData?.name ?? condition.value,
        };
      }
    );
  }

  const line: ILineConfig = {
    ...workflowDirection,
    workflowDefinitionId: workflowDirection.workflowDefinitionId,
    id: undefined,
    type: "line",
    stroke: "#2196F3",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    from: workflowDirection.fromStep.id.toString(),
    to: workflowDirection.toStep.id.toString(),
    fromSide: workflowDirection.fromSide,
    toSide: workflowDirection.toSide,
    forwardArrow: "filled",
    title: {
      editable: false,
      text: conditions,
    },
    isOld: true,
  };

  return line;
}

// export function toSwimlane(
//   workflowDefinition: WorkflowDefinition,
//   workflowRoles: Role[]
// ) {
//   const layoutSwimlaneConfig: any[] = [];
//   const subHeaderColHeadersConfig: any[] = [];

//   // const layoutSwimlaneConfig = workflowRoles.map((role) => role.id.toString());
//   // const subHeaderColHeadersConfig = workflowRoles.map((role) => {
//   //   return {
//   //     id: role.id.toString(),
//   //     text: role.name,
//   //   };
//   // });

//   const swimlane: ISwimlaneConfig = {
//     id: workflowDefinition.id.toString(),
//     type: "$swimlane",
//     layout: [layoutSwimlaneConfig],
//     height: 600,
//     width: 1770,
//     x: 0,
//     y: 0,
//     header: {
//       enable: true,
//       text: workflowDefinition.name,
//       fontSize: 14,
//       lineHeight: 14,
//       textAlign: "center",
//       textVerticalAlign: "center",
//       fontStyle: "normal",
//       fontWeight: "700",
//       fontColor: "#4C4C4C",
//       position: "bottom",
//       editable: false,
//       fill: "#F7F7F7",
//       height: 40,
//       iconColor: "#808080",
//       closable: false,
//     },
//     subHeaderCols: {
//       height: 40,
//       enable: true,
//       position: "top",
//       fontSize: 14,
//       lineHeight: 14,
//       textAlign: "center",
//       textVerticalAlign: "center",
//       fontStyle: "normal",
//       fontWeight: "500",
//       fontColor: "#4C4C4C",
//       iconColor: "#808080",
//       editable: false,
//       fill: "#F7F7F7",
//       headers: subHeaderColHeadersConfig,
//     },
//     open: true,
//     style: {
//       strokeWidth: 1,
//       stroke: "#CCC",
//       borderStyle: "solid",
//       fill: "inherit",
//       overFill: "rgba(46, 204, 113, 0.1)",
//       partiallyFill: "rgba(231, 76, 60, 0.1)",
//     },
//     entryArea: {
//       catchArea: 100,
//       borderFlexible: false,
//     },
//     exitArea: {
//       groupBehavior: "unbound",
//       padding: 0,
//     },
//     fixed: false,
//     groupChildren: layoutSwimlaneConfig,
//   };
//   return swimlane;
// }

// export function toGroup(workflowRole: Role): any {
//   const group: IGroupConfig = {
//     type: "$group",
//     id: workflowRole.id.toString(),
//     open: true,
//     style: {
//       strokeWidth: 1,
//       stroke: "#CCC",
//       borderStyle: "solid",
//       fill: "inherit",
//       overFill: "rgba(46, 204, 113, 0.1)",
//       partiallyFill: "rgba(231, 76, 60, 0.1)",
//     },
//     entryArea: {
//       catchArea: 25,
//       borderFlexible: false,
//     },
//     exitArea: {
//       groupBehavior: "unbound",
//       padding: 0,
//     },
//     fixed: false,
//     hidden: false,
//     groupChildren: workflowRole.workflowSteps?.map((step: any) => step.id),
//   };

//   return group;
// }

export function convertDiagramDataToModelData(
  model: WorkflowDefinition,
  leftPanelData: any[],
  editor: DiagramEditor
) {
  if (editor) {
    const editorParentData = editor.serialize();

    const workflowStepsValue = [...model.workflowSteps];
    workflowStepsValue.forEach((step: any) => {
      const stepIndex = editorParentData.findIndex(
        (x: any) =>
          x.type === "workflowStep" && x.id.toString() === step.id.toString()
      );
      if (stepIndex < 0) {
        const index = model.workflowSteps.findIndex(
          (x: any) => x.id === step.id
        );
        model.workflowSteps.splice(index, 1);
      }
    });

    const workflowDirectionsValue = [...model.workflowDirections];
    workflowDirectionsValue.forEach((direction: any) => {
      const directionIndex = editorParentData.findIndex(
        (x: any) =>
          x.type === "line" && x.id.toString() === direction.id.toString()
      );
      if (directionIndex < 0) {
        const index = model.workflowDirections.findIndex(
          (x: any) => x.id === direction.id
        );
        model.workflowDirections.splice(index, 1);
      }
    });

    editorParentData.forEach((item: any) => {
      if (item.type === "workflowStep") {
        const step = model.workflowSteps.find(
          (x: any) => x.id.toString() === item.id.toString()
        );

        if (step) {
          Object.assign(step, item);
          step.stepId = step.id;
          if (step.secondId) {
            step.id = parseInt(step.secondId);
          }
          step.xCoordinate = item.x;
          step.yCoordinate = item.y;
          step.width = item.width;
          step.height = item.height;
          step.$item = undefined;
        } else {
          const newStep: WorkflowStep = {
            stepId: item.id,
            rowId: uuidv4(),
            ...item,
          };
          newStep.stepId = newStep.id;
          if (newStep.secondId) {
            newStep.id = parseInt(newStep.secondId);
          } else {
            newStep.id = 0;
          }

          newStep.xCoordinate = item.x;
          newStep.yCoordinate = item.y;
          newStep.$item = undefined;
          model.workflowSteps.push(newStep);
        }
      }

      if (item.type === "line") {
        const direction = model.workflowDirections.find(
          (x: any) => x.id.toString() === item.id.toString()
        );
        if (direction) {
          Object.assign(direction, item);
          direction.fromSide = item.fromSide;
          direction.toSide = item.toSide;
          direction.$item = undefined;

          item?.title?.text?.forEach((txt: any) => {
            const index = direction.workflowDirectionConditions?.findIndex(
              (cond) =>
                cond.id?.toString() === txt.conditionId.toString() ||
                cond.rowId?.toString() === txt.conditionId.toString()
            );
            if (typeof index !== "undefined" && !(index < 0)) {
              direction.workflowDirectionConditions[index].coordinate =
                txt.distance;
            }
          });
        } else {
          const newDirection: WorkflowDirection = {
            ...item,
            rowId: uuidv4(),
          };
          newDirection.id = 0;
          newDirection.fromStep = model.workflowSteps.find(
            (x: any) => x.id.toString() === item.from || x.stepId === item.from
          );
          newDirection.toStep = model.workflowSteps.find(
            (x: any) => x.id.toString() === item.to || x.stepId === item.to
          );
          newDirection.$item = undefined;
          model.workflowDirections.push(newDirection);
        }
      }
    });
  }

  leftPanelData.forEach((item) => {
    if (item.secondId) {
      item.id = parseInt(item.secondId);
    }

    const step = model.workflowSteps.find(
      (x: any) => x.id.toString() === item.id.toString()
    );

    if (step) {
      Object.assign(step, item);
      if (step.secondId) {
        step.id = parseInt(step.secondId);
      }
      step.xCoordinate = item.x;
      step.yCoordinate = item.y;
      step.width = item.width;
      step.height = item.height;
      step.$item = undefined;
    } else {
      const newStep: WorkflowStep = {
        stepId: item.id,
        rowId: uuidv4(),
        ...item,
      };
      if (newStep.secondId) {
        newStep.id = parseInt(newStep.secondId);
        if (isNaN(newStep.id)) {
          newStep.id = 0;
        }
      } else {
        newStep.id = 0;
      }
      newStep.xCoordinate = item.x;
      newStep.yCoordinate = item.y;
      newStep.$item = undefined;
      model.workflowSteps.push(newStep);
    }
  });
}
