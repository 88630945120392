/* begin general import */
/* end general import */
/* begin individual import */
import { Role } from "models/Role";
import React from "react";
import { FormItem, StandardTable } from "react3l-ui-library";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import nameof from "ts-nameof.macro";
import { useGeneralActionHook } from "./GeneralActionTypeHook";
/* end individual import */

interface PermissionGeneralActionTypeProps {
  model: Role;
  setModel?: (data: Role) => void;

  dispatchModel?: React.Dispatch<ModelAction<Role>>;
}

function PermissionGeneralActionType(props: PermissionGeneralActionTypeProps) {
  const { model, dispatchModel } = props;

  const {
    generalActionTypeList,
    generalActionTypeRowSelection,
    generalActionTypeColumns,
  } = useGeneralActionHook(model, dispatchModel);

  return (
    <div>
      <StandardTable
        rowKey={nameof(generalActionTypeList[0].key)}
        columns={generalActionTypeColumns}
        dataSource={generalActionTypeList}
        tableSize={"lg"}
        rowSelection={generalActionTypeRowSelection}
        scroll={{ y: 450 }}
      />
      <FormItem
        validateStatus={utilService.getValidateStatus(
          model,
          nameof(model.generalActionTypes)
        )}
        message={model.errors?.generalActionTypes}
      >
        {""}
      </FormItem>
    </div>
  );
}

export default PermissionGeneralActionType;
