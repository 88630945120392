import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { Col, Popconfirm, Row } from "antd";
import { ColumnProps } from "antd/lib/table/Column";
import { TFunction } from "i18next";
import { AppUser } from "models/AppUser";
import { AppUserGrouping, AppUserGroupingFilter } from "models/AppUserGrouping";
import React, { useMemo } from "react";
import {
  Button,
  LayoutCell,
  LayoutHeader,
  Modal,
  OneLineText,
  Pagination,
  StandardTable,
} from "react3l-ui-library";
import InputSearch from "react3l-ui-library/build/components/Input/InputSearch";
import { appUserGroupingRepository } from "repositories/app-user-grouping-repository";
import { finalize, forkJoin } from "rxjs";
import { webService } from "services/common-services/web-service";
import nameof from "ts-nameof.macro";

export function useAppUserGroupingMappingHook() {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [subscription] = webService.useSubscription();
  const [appUserGroupingFilter, setAppUserGroupingFilter] =
    React.useState<AppUserGroupingFilter>({
      ...new AppUserGroupingFilter(),
      skip: 0,
      take: 10,
    });
  const [list, setList] = React.useState<AppUserGrouping[]>([]);
  const [total, setTotal] = React.useState<number>(undefined);
  const [loading, setLoading] = React.useState<boolean>();
  const [currentAppUser, setCurrentAppUser] = React.useState<AppUser>(null);

  const handleGetItemList = React.useCallback(
    async (filterValue) => {
      setLoading(true);
      const getNCountItems = forkJoin([
        appUserGroupingRepository.list(filterValue),
        appUserGroupingRepository.count(filterValue),
      ])
        .pipe(
          finalize(() => {
            setLoading(false);
          })
        )
        .subscribe(
          (results: [AppUser[], number]) => {
            if (results[0]) {
              setList([...results[0]]);
              setTotal(Number(results[1]));
            }
          },
          (errors: any) => {}
        );
      subscription.add(getNCountItems);
    },
    [subscription]
  );

  const handleOpenModal = React.useCallback(
    (id, appUser) => (event: any) => {
      setCurrentAppUser(appUser);
      handleGetItemList({
        ...new AppUserGroupingFilter(),
        appUserId: { equal: id },
        skip: 0,
        take: 10,
      });
      setAppUserGroupingFilter({
        ...new AppUserGroupingFilter(),
        appUserId: { equal: id },
        skip: 0,
        take: 10,
      });

      setVisible(true);
    },
    [handleGetItemList]
  );
  const handleCloseModal = React.useCallback(() => {
    setVisible(false);
  }, []);

  return {
    visible,
    list,
    total,
    appUserGroupingFilter,
    setAppUserGroupingFilter,
    loading,
    handleOpenModal,
    handleCloseModal,
    currentAppUser,
    handleGetItemList,
  };
}
interface AppUserGroupingAppUserMappingModalProps {
  visible: boolean;
  handleClose: () => void;
  translate: TFunction;
  appUserGroupingFilter?: AppUserGroupingFilter;
  listAppUserGrouping?: AppUserGrouping[];
  countAppUserGrouping?: number;
  loadList?: boolean;
  handleTableChange?: (value: any) => void;
  handlePagination?: (skip: number, take: number) => void;
  currentAppUser?: AppUser;
  setAppUserGroupingFilter?: any;
  handleGetItemList?: (filterValue: any) => Promise<void>;
  handleReloadListAppUser?: () => void;
}
export function AppUserGroupingMappingModal(
  props: AppUserGroupingAppUserMappingModalProps
) {
  const {
    visible,
    translate,
    appUserGroupingFilter,
    listAppUserGrouping,
    countAppUserGrouping,
    loadList,
    handleTableChange,
    handlePagination,
    handleClose,
    setAppUserGroupingFilter,
    currentAppUser,
    handleGetItemList,
    handleReloadListAppUser,
  } = props;

  const handleDeleteItem = React.useCallback(
    (id) => {
      appUserGroupingRepository
        .removeAppUserGrouping({
          appUserId: currentAppUser?.id,
          appUserGroupingId: id,
        })
        .subscribe(
          (res) => {
            setAppUserGroupingFilter({ ...appUserGroupingFilter });
            handleGetItemList(appUserGroupingFilter);
            handleReloadListAppUser();
          },
          (err) => {}
        );
    },
    [
      currentAppUser?.id,
      setAppUserGroupingFilter,
      appUserGroupingFilter,
      handleGetItemList,
      handleReloadListAppUser,
    ]
  );
  const handleFilter = React.useCallback(
    (value: any) => {
      const newFilter = { ...appUserGroupingFilter };
      newFilter["search"] = value;
      newFilter["skip"] = 0;
      setAppUserGroupingFilter({ ...newFilter });
      handleGetItemList(newFilter);
    },
    [appUserGroupingFilter, handleGetItemList, setAppUserGroupingFilter]
  );
  const columns: ColumnProps<AppUserGrouping>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.code")}
          />
        ),
        key: nameof(listAppUserGrouping[0].code),
        dataIndex: nameof(listAppUserGrouping[0].code),

        ellipsis: true,
        render(...params: [string, AppUserGrouping, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.name")}
          />
        ),
        key: nameof(listAppUserGrouping[0].name),
        dataIndex: nameof(listAppUserGrouping[0].name),

        ellipsis: true,
        render(...params: [string, AppUserGrouping, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        key: "action",
        dataIndex: nameof(listAppUserGrouping[0].id),
        fixed: "right",
        width: 150,
        align: "center",
        render(id: number, appUserGrouping: AppUserGrouping) {
          return (
            <Popconfirm
              placement="rightBottom"
              title={"Bạn có chắc chắn muốn xóa"}
              onConfirm={() => handleDeleteItem(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="icon-only-ghost"
                icon={<TrashCan16 />}
                className="btn--sm"
              />
            </Popconfirm>
          );
        },
      },
    ],
    [translate, listAppUserGrouping, handleDeleteItem]
  );
  return (
    <>
      <Modal
        size={720}
        visibleFooter={false}
        visible={visible}
        title={translate(`${currentAppUser?.name}`)}
        closable={true}
        handleCancel={handleClose}
      >
        <div className="modal-content">
          <Row>
            <Col lg={24}>
              <InputSearch
                valueFilter={appUserGroupingFilter}
                value={appUserGroupingFilter?.search}
                classFilter={AppUserGroupingFilter}
                placeHolder={translate("appUserGroupings.placeholder.name")}
                onChange={handleFilter}
                className="app-user-grouping__input-search"
                animationInput={false}
              />
            </Col>
          </Row>
          <StandardTable
            rowKey={nameof(listAppUserGrouping[0].id)}
            columns={columns}
            dataSource={listAppUserGrouping}
            isDragable={true}
            tableSize={"md"}
            onChange={handleTableChange}
            loading={loadList}
          />
          <Pagination
            skip={appUserGroupingFilter?.skip}
            take={appUserGroupingFilter?.take}
            total={countAppUserGrouping}
            onChange={handlePagination}
            canChangePageSize={false}
          />
        </div>
      </Modal>
    </>
  );
}
