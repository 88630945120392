/* begin general import */
import Close16 from "@carbon/icons-react/es/close/16";
import Send16 from "@carbon/icons-react/es/send/16";
import { Col, Modal, Row, Switch } from "antd";
import classNames from "classnames";
import DiagramEditor from "components/Diagram/DiagramEditor/DiagramEditor";
import { convertDiagramDataToModelData } from "components/Diagram/helper/convert-data";
import PageHeader from "components/PageHeader/PageHeader";
import {
  WORKFLOW_DEFINITION_DETAIL_ROUTE,
  WORKFLOW_DEFINITION_ROUTE,
} from "config/route-consts";
import { OrganizationFilter } from "models/Organization";
import { SiteFilter } from "models/Site";
import {
  WorkflowDefinition,
  WorkflowDefinitionFilter,
} from "models/WorkflowDefinition";
import { WorkflowTypeFilter } from "models/WorkflowType";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "react3l-ui-library";
import DatePicker from "react3l-ui-library/build/components/Calendar/DatePicker/DatePicker";
import FormItem from "react3l-ui-library/build/components/FormItem";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import Select from "react3l-ui-library/build/components/Select/SingleSelect/Select";
import TreeSelect from "react3l-ui-library/build/components/TreeSelect/TreeSelect";
import { workflowDefinitionRepository } from "repositories/workflow-definition-repository";
import appMessageService from "services/common-services/app-message-service";
import { utilService } from "services/common-services/util-service";
import { webService } from "services/common-services/web-service";
import { detailService } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import nameof from "ts-nameof.macro";
import workflowDefinitionDetailStyle from "./WorkflowDefinitionDetail.module.scss";
import useWorkflowDefinitionDetail from "./WorkflowDefinitionDetailHook/WorkflowDefinitionDetailHook";
import WorkflowDirectionDetailModal from "./WorkflowDirectionDetailModal/WorkflowDirectionDetailModal";
import useWorkflowDirectionDetailModal from "./WorkflowDirectionDetailModal/WorkflowDirectionDetailModalHook";
import WorkflowStepDetailModal from "./WorkflowStepDetailModal/WorkflowStepDetailModal";
import useWorkflowStepDetailModal from "./WorkflowStepDetailModal/WorkflowStepDetailModalHook";
/* end individual import */

function WorkflowDefinitionDetail() {
  const [translate] = useTranslation();
  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);
  const { model, dispatch } =
    detailService.useModel<WorkflowDefinition>(WorkflowDefinition);
  const { isDetail } = detailService.useGetIsDetail<WorkflowDefinition>(
    workflowDefinitionRepository.get,
    dispatch
  );
  const [editorParent, setEditorParent] =
    React.useState<dhx.DiagramEditor>(undefined);
  const isNew = React.useRef(true);

  const { diagramData, leftPanelData, newStepLeftPanel, setInactiveSteps } =
    useWorkflowDefinitionDetail(model, editorParent);


  const [isLoadModel,setIsLoadModel] = React.useState<boolean>(true);
  // oldmodel use when model.used = true and then only can update status
  const [oldModel,setOldModel] = React.useState<WorkflowDefinition>(new WorkflowDefinition());
  React.useEffect(()=>{
    if(isLoadModel && model?.id){
      setOldModel({...model});
      setIsLoadModel(false);
    }
  },[isLoadModel, model])

  const {
    model: modelWorkflowStepDetailModal,
    dispatch: dispatchWorkflowStepDetailModal,
    visibleModal: visibleWorkflowStepDetailModal,
    openModal: openWorkflowStepDetailModal,
    closeModal: closeWorkflowStepDetailModal,
    saveModal: saveWorkflowStepDetailModal,
  } = useWorkflowStepDetailModal(editorParent, setInactiveSteps);

  const {
    model: modelWorkflowDirectionDetailModal,
    dispatch: dispatchWorkflowDirectionDetailModal,
    visibleModal: visibleWorkflowDirectionDetailModal,
    openModal: openWorkflowDirectionDetailModal,
    closeModal: closeWorkflowDirectionDetailModal,
    saveModal: saveWorkflowDirectionDetailModal,
  } = useWorkflowDirectionDetailModal(editorParent);

  const {
    handleChangeDateField,
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeTreeField,
    handleChangeSelectField,
  } = fieldService.useField(model, dispatch);

  const handleCreateWorkflowStep = React.useCallback(
    (shape: any) => {
      if (shape.statusId === 0) {
        shape.statusId = 1;
        setInactiveSteps((origin) => {
          const originValue = [...origin];
          const index = originValue.findIndex((x) => x.id === shape.secondId);
          originValue.splice(index, 1);
          return originValue;
        });
      } else {
        shape.code = null;
        shape.name = null;
        shape.isNew = true;
        shape.workflowDefinitionId = model.id;
        shape.statusId = 1;
        openWorkflowStepDetailModal(shape);
      }
    },
    [model.id, openWorkflowStepDetailModal, setInactiveSteps]
  );

  const handleClickInfoWorkflowStep = React.useCallback(
    (shape: any) => {
      openWorkflowStepDetailModal(shape);
    },
    [openWorkflowStepDetailModal]
  );

  const handleCreateWorkflowDirection = React.useCallback(
    (line: any) => {
      line.isNew = true;
      line.workflowDefinitionId = model.id;
      line.workflowTypeId = model.workflowTypeId;
      line.statusId = 1;
      openWorkflowDirectionDetailModal(line);
    },
    [model.id, model.workflowTypeId, openWorkflowDirectionDetailModal]
  );

  const handleClickInfoWorkflowDirection = React.useCallback(
    (line: any) => {
      line.workflowTypeId = model.workflowTypeId;
      openWorkflowDirectionDetailModal(line);
    },
    [model.workflowTypeId, openWorkflowDirectionDetailModal]
  );

  const { handleGoMaster } = detailService.useActionsDetail<WorkflowDefinition>(
    model,
    workflowDefinitionRepository.save,
    handleChangeAllField,
    WORKFLOW_DEFINITION_ROUTE
  );
  const [subscription] = webService.useSubscription();
  const { notifyUpdateItemSuccess, notifyUpdateItemError } =
    appMessageService.useCRUDMessage();
  const history = useHistory();

  const handleSaveModel = React.useCallback(() => {
    // oldmodel use when model.used = true and then only can update status
    let tmpModel = new WorkflowDefinition();
    if (typeof model.used === undefined || !model.used) {
      tmpModel = model;
    }
    else {
      tmpModel = {
        ...oldModel,
        status: model.status,
        statusId: model.statusId,
      }
    }

    subscription.add(
      workflowDefinitionRepository.save(tmpModel).subscribe({
        next: (res) => {
          handleChangeAllField(res); // setModel
          notifyUpdateItemSuccess();
          if (isDetail || !isNew.current) {
            handleGoMaster(); // go master
          } else {
            isNew.current = false;
            history.push(`${WORKFLOW_DEFINITION_DETAIL_ROUTE}?id=${res.id}`);
          }
        },
        error: (err) => {
          if (err.response && err.response.status === 400)
            handleChangeAllField(err.response?.data);
          notifyUpdateItemError();
        },
      })
    );
  }, [handleChangeAllField, handleGoMaster, history, isDetail, model, notifyUpdateItemError, notifyUpdateItemSuccess, oldModel, subscription]);

  const onClickSave = React.useCallback(() => {
    convertDiagramDataToModelData(model, leftPanelData, editorParent);
    // Nếu trạng thái của model là Hoạt động thì cần xét điều kiện để đưa ra alert
    if (typeof model.used === undefined || !model.used) {
      workflowDefinitionRepository
        .list(new WorkflowDefinitionFilter())
        .subscribe((res: WorkflowDefinition[]) => {
          if (res && res?.length > 0) {
            let count = 0;
            // eslint-disable-next-line array-callback-return
            res.map((workflowDefinition) => {
              if (
                workflowDefinition?.organizationId === model?.organizationId &&
                workflowDefinition?.workflowTypeId === model?.workflowTypeId &&
                workflowDefinition?.id !== model?.id
              ) {
                count++;
              }
            });
            if (count > 0) {
              Modal.confirm({
                title: translate("workflowDefinitions.notifications.save"),
                okType: "danger",
                onOk() {
                  handleSaveModel();
                },
              });
            } else {
              handleSaveModel();
            }
          } else {
            handleSaveModel();
          }
        });
    }else {
      handleSaveModel();
    }
  }, [editorParent, handleSaveModel, leftPanelData, model, translate]);

  const ref = React.useRef<boolean>(true);
  React.useEffect(() => {
    if (ref.current && !model?.id) {
      handleChangeAllField({ ...model, statusId: 1 });
      ref.current = false;
    }
  }, [model, handleChangeAllField]);

  const handleChangeStatus = React.useCallback(
    (checked) => {
      const newModel = { ...model };
      if (checked) {
        newModel.statusId = 1;
      } else {
        newModel.statusId = 0;
      }
      handleChangeAllField(newModel);
    },
    [handleChangeAllField, model]
  );

  const handleChangeSite = React.useCallback(
    () => (idValue: number, value: any) => {
      const newModel = { ...model };
      newModel.siteId = idValue;
      newModel.site = value;
      newModel.workflowType = undefined;
      newModel.workflowTypeId = undefined;
      handleChangeAllField(newModel);
    },
    [handleChangeAllField, model]
  );

  const getFullScreenIframe = React.useCallback(() => {
    return document.fullscreenElement;
  }, []);

  const toggleFullScreenIframe = React.useCallback(() => {
    if (getFullScreenIframe() && isFullScreen) {
      document.exitFullscreen();
      setIsFullScreen(false);
    } else {
      document.getElementById("diagram-screen").requestFullscreen();
      setIsFullScreen(true);
    }
  }, [getFullScreenIframe, isFullScreen]);

  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("workflowDefinitions.master.subHeader")}
          breadcrumbItems={[
            translate("workflowDefinitions.master.header"),
            translate("workflowDefinitions.master.subHeader"),
          ]}
        />
        <div
          className={classNames(
            "page page-detail p-t--lg p-l--xxl p-r--xxl p-b--lg",
            workflowDefinitionDetailStyle["workflow__definition__page__detail"]
          )}
        >
          <div className="page-detail__title p-b--lg">
            {!isDetail
              ? translate("general.actions.create")
              : translate("general.detail.title")}
          </div>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col lg={6} className="m-b--sm m-t--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.code)
                )}
                message={model.errors?.code}
              >
                <InputText
                  label={translate("workflowDefinitions.code")}
                  type={0}
                  value={model.code}
                  placeHolder={translate(
                    "workflowDefinitions.placeholder.code"
                  )}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeSingleField({
                    fieldName: nameof(model.code),
                  })}
                  isRequired
                />
              </FormItem>
            </Col>

            <Col lg={6} className="m-b--sm m-t--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.name)
                )}
                message={model.errors?.name}
              >
                <InputText
                  isRequired
                  label={translate("workflowDefinitions.name")}
                  type={0}
                  value={model.name}
                  placeHolder={translate(
                    "workflowDefinitions.placeholder.name"
                  )}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeSingleField({
                    fieldName: nameof(model.name),
                  })}
                />
              </FormItem>
            </Col>

            <Col lg={6} className="m-b--sm m-t--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.startDate)
                )}
                message={model.errors?.startDate}
              >
                <DatePicker
                  isRequired
                  label={translate("workflowDefinitions.startDate")}
                  value={model.startDate}
                  type={0}
                  onChange={handleChangeDateField({
                    fieldName: nameof(model.startDate),
                  })}
                  placeholder={translate(
                    "workflowDefinitions.placeholder.startDate"
                  )}
                />
              </FormItem>
            </Col>

            <Col lg={6} className="m-b--sm m-t--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.endDate)
                )}
                message={model.errors?.endDate}
              >
                <DatePicker
                  label={translate("workflowDefinitions.endDate")}
                  value={model.endDate}
                  type={0}
                  onChange={handleChangeDateField({
                    fieldName: nameof(model.endDate),
                  })}
                  placeholder={translate(
                    "workflowDefinitions.placeholder.endDate"
                  )}
                />
              </FormItem>
            </Col>

            <Col lg={6} className="m-b--sm m-t--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.organization)
                )}
                message={model.errors?.organization}
              >
                <TreeSelect
                  isRequired
                  label={translate("workflowDefinitions.organization")}
                  type={0}
                  placeHolder={translate(
                    "workflowDefinitions.placeholder.organization"
                  )}
                  selectable={true}
                  classFilter={OrganizationFilter}
                  onChange={handleChangeTreeField({
                    fieldName: nameof(model.organization),
                  })}
                  checkStrictly={true}
                  getTreeData={
                    workflowDefinitionRepository.singleListOrganization
                  }
                  item={model.organization}
                />
              </FormItem>
            </Col>
            <Col lg={6} className="m-b--sm m-t--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.site)
                )}
                message={model.errors?.site}
              >
                <Select
                  isRequired
                  label={translate("workflowDefinitions.site")}
                  type={0}
                  classFilter={SiteFilter}
                  searchProperty={"name"}
                  placeHolder={translate(
                    "workflowDefinitions.placeholder.site"
                  )}
                  getList={workflowDefinitionRepository.singleListSite}
                  onChange={handleChangeSite()}
                  value={model.site}
                />
              </FormItem>
            </Col>
            <Col lg={6} className="m-b--sm m-t--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.workflowType)
                )}
                message={model.errors?.workflowType}
              >
                <Select
                  isRequired
                  label={translate("workflowDefinitions.workflowType")}
                  type={0}
                  classFilter={WorkflowTypeFilter}
                  searchProperty={"name"}
                  placeHolder={translate(
                    "workflowDefinitions.placeholder.workflowType"
                  )}
                  getList={workflowDefinitionRepository.singleListWorkflowType}
                  onChange={handleChangeSelectField({
                    fieldName: nameof(model.workflowType),
                  })}
                  valueFilter={{
                    ...new WorkflowTypeFilter(),
                    siteId: { equal: model?.siteId },
                  }}
                  value={model.workflowType}
                  disabled={!model?.siteId}
                />
              </FormItem>
            </Col>
            <Col lg={6} className="m-b--sm m-t--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.status)
                )}
                message={model.errors?.status}
              >
                <div>
                  <div className="label-status">
                    {translate("workflowDefinitions.status")}
                  </div>
                  <Switch
                    checked={model.statusId === 1 ? true : false}
                    onChange={handleChangeStatus}
                    className="switch_status"
                  />
                </div>
              </FormItem>
            </Col>
          </Row>
          {model.id && (
            <div
              className={classNames(
                workflowDefinitionDetailStyle["diagram__container"]
              )}
              id="diagram-screen"
            >
              <div
                className={classNames(
                  workflowDefinitionDetailStyle["diagram__full-screen_button"]
                )}
              >
                <button
                  type="button"
                  className={`btn noti-icon `}
                  onClick={() => {
                    toggleFullScreenIframe();
                  }}
                  data-toggle="fullscreen"
                >
                  {isFullScreen ? (
                    <i className="bx bx-exit-fullscreen" />
                  ) : (
                    <i className="bx bx-fullscreen" />
                  )}
                </button>
              </div>
              <DiagramEditor
                setEditorParent={setEditorParent}
                data={diagramData}
                leftPanelData={leftPanelData}
                onCreateLine={handleCreateWorkflowDirection}
                onCreateShape={handleCreateWorkflowStep}
                onClickInfoShape={handleClickInfoWorkflowStep}
                onClickInfoLine={handleClickInfoWorkflowDirection}
                newStepLeftPanel={newStepLeftPanel}
              />
            </div>
          )}
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>
              <Button
                type="primary"
                className="btn--lg"
                icon={<Send16 />}
                onClick={onClickSave}
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
      <WorkflowStepDetailModal
        model={modelWorkflowStepDetailModal}
        dispatch={dispatchWorkflowStepDetailModal}
        visible={visibleWorkflowStepDetailModal}
        handleSave={saveWorkflowStepDetailModal}
        handleClose={closeWorkflowStepDetailModal}
      />
      <WorkflowDirectionDetailModal
        model={modelWorkflowDirectionDetailModal}
        dispatch={dispatchWorkflowDirectionDetailModal}
        visible={visibleWorkflowDirectionDetailModal}
        handleSave={saveWorkflowDirectionDetailModal}
        handleClose={closeWorkflowDirectionDetailModal}
      />
    </>
  );
}

export default WorkflowDefinitionDetail;
