import { DateFilter, IdFilter, StringFilter } from "react3l-advanced-filters";
import { ModelFilter } from "react3l-common";
import { Field, ObjectField } from "react3l-decorators";

export class MenuReportFilter extends ModelFilter {
  @ObjectField(IdFilter)
  public id?: IdFilter = new IdFilter();
  @ObjectField(StringFilter)
  public code?: StringFilter = new StringFilter();
  @ObjectField(StringFilter)
  public name?: StringFilter = new StringFilter();
  @ObjectField(IdFilter)
  public siteId?: IdFilter = new IdFilter();
  @ObjectField(IdFilter)
  public menuId?: IdFilter = new IdFilter();
  @ObjectField(DateFilter)
  public updatedAt?: DateFilter = new DateFilter();
  @Field(String)
  public search?: string;
}
