import { Repository } from "react3l-common";
import { kebabCase } from "lodash";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { map, Observable } from "rxjs";
import { AxiosResponse } from "axios";
import nameof from "ts-nameof.macro";
import { API_MENU_REPORT_PREFIX } from "config/api-consts";
import { MenuReport, MenuReportFilter } from "models/MenuReport";
import { Site, SiteFilter } from "models/Site";
import { Menu, MenuFilter } from "models/Menu";
import { Page, PageFilter } from "models/Page";
import { EnumFilter, EnumModel } from "models/Enum";
import { Status, StatusFilter } from "models/Status";
import { OrganizationFilter } from "models/Organization/OrganizationFilter";
import { Organization } from "models/Organization";
import { FileTemplate } from "models/FileTemplate";

export type KeyType = string | number;

export class MenuReportRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = new URL(API_MENU_REPORT_PREFIX, BASE_API_URL).href;
  }

  public count = (menuReportFilter?: MenuReportFilter): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.count)), menuReportFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public list = (
    menuReportFilter?: MenuReportFilter
  ): Observable<MenuReport[]> => {
    return this.http
      .post<MenuReport[]>(kebabCase(nameof(this.list)), menuReportFilter)
      .pipe(Repository.responseMapToList<MenuReport>(MenuReport));
  };

  public get = (id: number): Observable<MenuReport> => {
    return this.http
      .post<MenuReport>(kebabCase(nameof(this.get)), { id })
      .pipe(Repository.responseMapToModel<MenuReport>(MenuReport));
  };

  public create = (menuReport: MenuReport): Observable<MenuReport> => {
    return this.http
      .post<MenuReport>(kebabCase(nameof(this.create)), menuReport)
      .pipe(Repository.responseMapToModel<MenuReport>(MenuReport));
  };

  public update = (menuReport: MenuReport): Observable<MenuReport> => {
    return this.http
      .post<MenuReport>(kebabCase(nameof(this.update)), menuReport)
      .pipe(Repository.responseMapToModel<MenuReport>(MenuReport));
  };

  public delete = (menuReport: MenuReport): Observable<MenuReport> => {
    return this.http
      .post<MenuReport>(kebabCase(nameof(this.delete)), menuReport)
      .pipe(Repository.responseMapToModel<MenuReport>(MenuReport));
  };

  public bulkDelete = (idList: KeyType[]): Observable<void> => {
    return this.http
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(Repository.responseDataMapper());
  };

  public save = (menuReport: MenuReport): Observable<MenuReport> => {
    return menuReport.id ? this.update(menuReport) : this.create(menuReport);
  };

  public upload = (files?: File[] | Blob[]): Observable<any> => {
    const formData: FormData = new FormData();
    formData.append("file", files[0] as Blob);
    return this.http
      .post<File>(kebabCase(nameof(this.upload)), formData)
      .pipe(map((r) => [r.data]));
  };

  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.http
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(Repository.responseDataMapper<any>());
  };

  public export = (filter: any): Observable<AxiosResponse<any>> => {
    return this.http.post("export", filter, {
      responseType: "arraybuffer",
    });
  };

  public exportTemplate = (): Observable<AxiosResponse<any>> => {
    return this.http.post(
      "export-template",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };

  public filterListSite = (siteFilter: SiteFilter): Observable<Site[]> => {
    return this.http
      .post<Site[]>(kebabCase(nameof(this.filterListSite)), siteFilter)
      .pipe(Repository.responseMapToList<Site>(Site));
  };

  public filterListMenu = (menuFilter: MenuFilter): Observable<Menu[]> => {
    return this.http
      .post<Menu[]>(kebabCase(nameof(this.filterListMenu)), menuFilter)
      .pipe(Repository.responseMapToList<Menu>(Menu));
  };

  public filterListPage = (pageFilter: PageFilter): Observable<Page[]> => {
    return this.http
      .post<Page[]>(kebabCase(nameof(this.filterListPage)), pageFilter)
      .pipe(Repository.responseMapToList<Page>(Page));
  };

  public filterListDefault = (
    defaultFilter: EnumFilter
  ): Observable<EnumModel[]> => {
    return this.http
      .post<EnumModel[]>(
        kebabCase(nameof(this.filterListDefault)),
        defaultFilter
      )
      .pipe(Repository.responseMapToList<EnumModel>(EnumModel));
  };

  public filterListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.filterListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public singleListOrganization = (
    organizationFilter: OrganizationFilter
  ): Observable<Organization[]> => {
    return this.http
      .post<Organization[]>(
        kebabCase(nameof(this.singleListOrganization)),
        organizationFilter
      )
      .pipe(Repository.responseMapToList<Organization>(Organization));
  };

  public validateInputs = (
    fileTemplate: FileTemplate
  ): Observable<FileTemplate> => {
    return this.http
      .post<FileTemplate>(kebabCase(nameof(this.validateInputs)), fileTemplate)
      .pipe(Repository.responseMapToModel<FileTemplate>(FileTemplate));
  };
}

export const menuReportRepository = new MenuReportRepository();
