import { API_PROFILE_PREFIX } from "config/api-consts";
import { BASE_API_URL } from "config/consts";
import { httpConfig } from "config/http";
import { kebabCase } from "lodash";
import { AppUser } from "models/AppUser";
import { Repository } from "react3l-common";
import { Observable } from "rxjs";
import nameof from "ts-nameof.macro";

export type KeyType = string | number;

export class ProfileRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.baseURL = new URL(API_PROFILE_PREFIX, BASE_API_URL).href;
    }

    // public updateCurrentProject = (
    //   currentProjectId: number | string
    // ): Observable<Project> => {
    //   return this.http
    //     .post<Project>(kebabCase(nameof(this.updateCurrentProject)), {
    //       currentProjectId,
    //     })
    //     .pipe(Repository.responseMapToModel<Project>(Project));
    // };

    // public recentListProject = (
    //   projectFilter: ProjectFilter
    // ): Observable<Project[]> => {
    //   return this.http
    //     .post<Project[]>(kebabCase(nameof(this.recentListProject)), projectFilter)
    //     .pipe(Repository.responseMapToList<Project>(Project));
    // };
    public login = (user: any): Observable<AppUser> => {
        return this.http.post<AppUser>
            (kebabCase(nameof(this.login)), user)
            .pipe(Repository.responseMapToModel<AppUser>(AppUser));
    };
    public loginByGmail = (user: any): Observable<AppUser> => {
        return this.http.post<AppUser>
            (kebabCase(nameof(this.loginByGmail)), user)
            .pipe(Repository.responseMapToModel<AppUser>(AppUser));
    };
    public loginByAdfs = (user: any): Observable<AppUser> => {
        return this.http.post<AppUser>
            (kebabCase(nameof(this.loginByAdfs)), user)
            .pipe(Repository.responseMapToModel<AppUser>(AppUser));
    };
    public get = (): Observable<AppUser> => {
        return this.http.post<AppUser>
            (kebabCase(nameof(this.get)),)
            .pipe(Repository.responseMapToModel<AppUser>(AppUser));
    };
    public verifyOtpCode = (obj: any): Observable<AppUser> => {
        return this.http.post<AppUser>
            (kebabCase(nameof(this.verifyOtpCode)), obj)
            .pipe(Repository.responseMapToModel<AppUser>(AppUser));
    };
    public forgotPassword = (email: string): Observable<AppUser> => {
        return this.http.post<AppUser>
            (kebabCase(nameof(this.verifyOtpCode)), { email })
            .pipe(Repository.responseMapToModel<AppUser>(AppUser));
    };
    public recoveryPassword = (password: string): Observable<AppUser> => {
        return this.http.post<AppUser>
            (kebabCase(nameof(this.verifyOtpCode)), { password })
            .pipe(Repository.responseMapToModel<AppUser>(AppUser));
    };
}

export const profileRepository = new ProfileRepository();
