import { MENU_REPORT_ROUTE } from "config/route-consts";
import React from "react";
import { useTranslation } from "react-i18next";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import { Menu, Dropdown } from "antd";
import { ColumnProps } from "antd/lib/table";
import {
  LayoutCell,
  LayoutHeader,
  OneLineText,
  StatusLine,
} from "react3l-ui-library";
import nameof from "ts-nameof.macro";
import { Menu as MenuModel } from "models/Menu";
import { Status } from "models/Status";
import OverflowMenuHorizontal24 from "@carbon/icons-react/es/overflow-menu--horizontal/24";
import { Site } from "models/Site";
import { MenuReport, MenuReportFilter } from "models/MenuReport";
import { menuReportRepository } from "repositories/menu-report-repository";

export function useMenuReportMasterHook() {
  const [translate] = useTranslation();
  const [modelFilter, dispatch] = queryStringService.useQueryString(
    MenuReportFilter,
    { skip: 0, take: 10 }
  );
  const [visible, setVisible] = React.useState<boolean>(false);

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList } = listService.useList(
    menuReportRepository.list,
    menuReportRepository.count,
    filter,
    dispatch
  );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    selectedRowKeys,
    setSelectedRowKeys,
  } = listService.useRowSelection(
    menuReportRepository.delete,
    menuReportRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const { handleGoCreate, handleGoDetail, handleGoPreview } =
    masterService.useMasterAction(MENU_REPORT_ROUTE, handleAction);

  const menuAction = React.useCallback(
    (id: number, menuReport: MenuReport) => (
      <Menu>
        <Menu.Item key="1">
          <div className="ant-action-menu" onClick={handleGoPreview(id)}>
            {translate("general.actions.view")}
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div
            className="ant-action-menu text-center"
            onClick={handleGoDetail(id)}
          >
            {translate("general.actions.edit")}
          </div>
        </Menu.Item>
      </Menu>
    ),
    [handleGoPreview, translate, handleGoDetail]
  );

  const columns: ColumnProps<MenuReport>[] = React.useMemo(
    () => [
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "site"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("menuReports.site")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].site),
        dataIndex: nameof(list[0].site),
        sorter: true,
        ellipsis: true,
        //width: 120,
        sortOrder: getAntOrderType<MenuReport, MenuReportFilter>(
          filter,
          nameof(list[0].site)
        ),
        render(...params: [Site, MenuReport, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]?.name} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "menu"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("menuReports.menu")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].menu),
        dataIndex: nameof(list[0].menu),
        sorter: true,
        ellipsis: true,
        //width: 120,
        sortOrder: getAntOrderType<MenuReport, MenuReportFilter>(
          filter,
          nameof(list[0].menu)
        ),
        render(...params: [MenuModel, MenuReport, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]?.name} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "name"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("menuReports.name")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<MenuReport, MenuReportFilter>(
          filter,
          nameof(list[0].name)
        ),
        //width: 120,
        ellipsis: true,
        render(...params: [string, MenuReport, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "status"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("menuReports.status")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].status),
        dataIndex: nameof(list[0].status),
        sorter: true,
        sortOrder: getAntOrderType<MenuReport, MenuReportFilter>(
          filter,
          nameof(list[0].status)
        ),
        width: 150,
        ellipsis: true,
        render(status: Status) {
          return (
            //fill the render field after generate code;
            <LayoutCell orderType="left" tableSize="md">
              <StatusLine value={status?.name} color={status?.color} />
            </LayoutCell>
          );
        },
      },

      {
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, menuReport: MenuReport) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, menuReport)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, list, filter, menuAction]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);

  return {
    translate,
    handleChangeSelectFilter,
    handleChangeInputSearch,
    handleTableChange,
    handlePagination,
    handleCancel,
    handleGoCreate,
    handleBulkAction,
    setSelectedRowKeys,
    setVisible,
    handleChangeAllFilter,
    filter,
    canBulkAction,
    selectedRowKeys,
    columns,
    list,
    count,
    loadingList,
    visible,
  };
}
