/* begin general import */
import { Close16 } from "@carbon/icons-react";
import { Col, Row, Tooltip } from "antd";
import classnames from "classnames";
// import DiagramEditor from "components/Diagram/DiagramEditor/DiagramEditor";
import DiagramView from "components/Diagram/DiagramView/DiagramView";
import PageHeader from "components/PageHeader/PageHeader";
import { WORKFLOW_DEFINITION_ROUTE } from "config/route-consts";
import { formatDateTime } from "helpers/date-time";
// import { ASSETS_IMAGE } from "config/consts";
/* end general import */
/* begin individual import */
import { WorkflowDefinition } from "models/WorkflowDefinition";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react3l-ui-library";
import { workflowDefinitionRepository } from "repositories/workflow-definition-repository";
import { detailService } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import { utilService } from "services/common-services/util-service";
import workflowDefinitionPreviewStyle from "./WorkflowDefinitionPreview.module.scss";
import useWorkflowDefinitionPreview from "./WorkflowDefinitionPreviewHook/WorkflowDefinitionPreviewHook";
/* end individual import */

function WorkflowDefinitionPreview() {
  const [translate] = useTranslation();
  const { model, dispatch } =
    detailService.useModel<WorkflowDefinition>(WorkflowDefinition);
  const { isDetail } = detailService.useGetIsDetail<WorkflowDefinition>(
    workflowDefinitionRepository.get,
    dispatch
  );

  const { diagramData } = useWorkflowDefinitionPreview(model);

  const { handleChangeAllField } = fieldService.useField(model, dispatch);
  const { handleGoMaster } = detailService.useActionsDetail<WorkflowDefinition>(
    model,
    workflowDefinitionRepository.save,
    handleChangeAllField,
    WORKFLOW_DEFINITION_ROUTE
  );

  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("workflowDefinitions.master.subHeader")}
          breadcrumbItems={[
            translate("workflowDefinitions.master.header"),
            translate("workflowDefinitions.master.subHeader"),
          ]}
        />
        <div className="page page-detail p-t--lg p-l--xxl p-r--xxl p-b--lg">
          <div className="page-detail__title p-b--lg">
            {!isDetail
              ? translate("general.actions.create")
              : translate("general.detail.title")}
          </div>
          <Row gutter={{ xs: 8, sm: 12, md: 24, lg: 32 }}>
            <Col lg={24} className="gutter-row">
              <Row>
                <Col span={6}>
                  <div className="">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.code")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <Tooltip title={model.code}>
                        {utilService.limitWord(model.code, 30)}
                      </Tooltip>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.name")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <Tooltip title={model.name}>
                        {utilService.limitWord(model.name, 30)}
                      </Tooltip>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="">
                    <div className="page-detail__title general-field__first-row">
                      <span>
                        {translate("workflowDefinitions.organization")}
                      </span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <Tooltip title={model.organization?.name}>
                        {utilService.limitWord(model.organization?.name, 30)}
                      </Tooltip>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="">
                    <div className="page-detail__title general-field__first-row">
                      <span>
                        {translate("workflowDefinitions.workflowType")}
                      </span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <Tooltip title={model.workflowType?.name}>
                        {utilService.limitWord(model.workflowType?.name, 30)}
                      </Tooltip>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="p-t--xxs">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.startDate")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <span>
                        {model.startDate
                          ? moment(model.startDate).format("DD/MM/YYYY")
                          : null}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="p-t--xxs">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.endDate")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <span>
                        {model.endDate
                          ? moment(model.endDate).format("DD/MM/YYYY")
                          : null}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="p-t--xxs">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.status")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <span>{model.status?.name}</span>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="p-t--xxs">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.site")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <span>{model.site?.name}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="page-detail__title m-b--lg p-t--lg">
            {translate("workflowDefinitions.preview.editInformation")}
          </div>
          <Row gutter={{ xs: 8, sm: 12, md: 24, lg: 32 }}>
            <Col lg={24} className="gutter-row">
              <Row>
                <Col span={6}>
                  <div className="">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.createdAt")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <span>{formatDateTime(model?.createdAt)}</span>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.creator")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <Tooltip title={model?.creator?.displayName}>
                        {utilService.limitWord(model?.creator?.displayName, 30)}
                      </Tooltip>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.updatedAt")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <span>{formatDateTime(model?.updatedAt)}</span>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="">
                    <div className="page-detail__title general-field__first-row">
                      <span>{translate("workflowDefinitions.modifier")}</span>
                    </div>
                    <div className="general-field__second-row p-t--xxs">
                      <Tooltip title={model?.modifier?.displayName}>
                        {utilService.limitWord(
                          model?.modifier?.displayName,
                          30
                        )}
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            className={classnames(
              workflowDefinitionPreviewStyle["diagram__container"],
              "m-t--md"
            )}
          >
            <DiagramView data={diagramData} scale={1} />
          </div>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default WorkflowDefinitionPreview;
