import React, { useEffect, useMemo, useRef, useState } from "react";
import * as dhx from "../codebase/diagramWithEditor";
import "../codebase/diagramWithEditor.css";
import "./DiagramEditor.scss";
import {
  workflowStepTemplate,
  workflowStepDefault,
} from "../helper/convert-data";
import ReactDOMServer from "react-dom/server";
import { Information24, TrashCan24 } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";

interface DiagramEditorProps {
  data: any[];
  leftPanelData: any[];
  setEditorParent?: React.Dispatch<React.SetStateAction<any>>;
  onCreateLine?: (line: any) => void;
  onCreateShape?: (shape: any) => void;
  onClickInfoShape?: (shape: any) => void;
  onClickInfoLine?: (line: any) => void;
  newStepLeftPanel?: any[];
}

function DiagramEditor(props: DiagramEditorProps) {
  const [translate] = useTranslation();

  const {
    data,
    leftPanelData,
    setEditorParent,
    onCreateLine,
    onCreateShape,
    onClickInfoShape,
    onClickInfoLine,
    newStepLeftPanel,
  } = props;

  const firstLoad = useRef(false);
  const isLoadInitData = useRef(false);

  const config = useMemo<any>(() => {
    if (leftPanelData) {
      return {
        controls: {
          apply: false,
          reset: true,
          import: false,
          export: false,
          gridStep: false,
          autoLayout: false,
        },
        shapeSections: {
          [translate("workflowSteps.newStep")]: newStepLeftPanel,
          [translate("workflowSteps.inactiveSteps")]: leftPanelData,
        },
        scalePreview: 0.8,
        shapeToolbar: [
          {
            id: "info",
            content: ReactDOMServer.renderToStaticMarkup(<Information24 />),
          },
          {
            id: "remove",
            content: ReactDOMServer.renderToStaticMarkup(<TrashCan24 />),
          },
        ],
      };
    }

    return;
  }, [leftPanelData, newStepLeftPanel, translate]);

  const [editor, setEditor] = useState<dhx.DiagramEditor>(undefined);

  useEffect(() => {
    if (config) {
      setEditor((oldEditor) => {
        let oldEditorData = null;

        if (oldEditor) {
          oldEditorData = oldEditor.serialize();
          oldEditor.unmount();
        }

        const editor = new dhx.DiagramEditor("editor", config);

        editor.diagram.addShape("workflowStep", {
          template: workflowStepTemplate,
          defaults: workflowStepDefault,
        });

        editor.diagram.events.on("afterAdd", function (shape) {
          if (shape.type === "workflowStep") {
            onCreateShape(shape);
          }
        });
        editor.diagram.events.on("change", function (id, status, shape) {
          if (status && shape) {
            if (
              shape.type === "line" &&
              shape.from &&
              shape.to &&
              status === "update"
            ) {
              if (!shape.isOld) {
                onCreateLine(shape);
              }
            }
          }
        });

        editor.events.on("afterShapeIconClick", function (iconId, shape) {
          if (iconId === "info") {
            if (shape.type === "workflowStep") {
              onClickInfoShape(shape);
            }
            if (shape.type === "line") {
              onClickInfoLine(shape);
            }
          }
        });
        editor.diagram.events.on("shapeDblClick", function (id: any) {
          const shape = editor.diagram.data.getItem(id);
          onClickInfoShape(shape);
        });

        if (!isLoadInitData.current) {
          if (data && data.length > 0) {
            editor.parse(data);
            isLoadInitData.current = true;
          } else {
            editor.parse([]);
          }
        } else if (oldEditorData !== null) {
          editor.parse(oldEditorData);
        } else {
          if (!firstLoad.current) {
            editor.parse([]);
            firstLoad.current = true;
          }
        }

        return editor;
      });
    }
  }, [
    config,
    data,
    onClickInfoLine,
    onClickInfoShape,
    onCreateLine,
    onCreateShape,
  ]);

  useEffect(() => {
    if (editor) {
      setEditorParent(editor);
    }
  }, [editor, setEditorParent]);

  return (
    <React.Fragment>
      <div id="editor" className="dhx__diagram__editor"></div>
    </React.Fragment>
  );
}

export default DiagramEditor;
