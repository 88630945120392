import { Col, Row, Switch, Tabs as TabAntd } from "antd";
import Editor from "components/Editor/Editor";
import { RoleFilter } from "models/Role";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Tabs } from "react3l-ui-library";
import FormItem from "react3l-ui-library/build/components/FormItem";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import Select from "react3l-ui-library/build/components/Select/SingleSelect/Select";
import { workflowStepRepository } from "repositories/workflow-step-repository";
import { utilService } from "services/common-services/util-service";
import { fieldService } from "services/page-services/field-service";
import nameof from "ts-nameof.macro";
import { WorkflowStepDetailModalProps } from "./WorkflowStepDetailModalHook";
const { TabPane } = TabAntd;

function WorkflowStepDetailModal(props: WorkflowStepDetailModalProps) {
  const [translate] = useTranslation();

  const { visible, handleClose, handleSave, model, dispatch } = props;
  const [listTabError, setListTabError] = React.useState<string[]>([]);
  const {
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeSelectField,
  } = fieldService.useField(model, dispatch);

  const handleChangeStatus = React.useCallback(
    (checked) => {
      const newModel = { ...model };
      if (checked) {
        newModel.statusId = 1;
      } else {
        newModel.statusId = 0;
      }
      handleChangeAllField(newModel);
    },
    [handleChangeAllField, model]
  );

  const handleChangeNotificationContent = React.useCallback(
    (fieldName: string) => {
      return (value: any) => {
        const newModel = { ...model };
        newModel[fieldName] = value.currentTarget.value;
        handleChangeAllField(newModel);
      };
    },
    [handleChangeAllField, model]
  );

  React.useEffect(() => {
    let tmpListTabErr: string[] = [];
    // check error tab infomation
    if (model?.errors?.code || model?.errors?.name || model?.errors?.role) {
      tmpListTabErr.push("tab-general");
    } else if (
      !(model?.errors?.code || model?.errors?.name || model?.errors?.role) &&
      tmpListTabErr?.length > 0
    ) {
      const tmpList = [...tmpListTabErr];
      tmpListTabErr = tmpList.filter((e) => e !== "tab-general");
    }

    setListTabError([...tmpListTabErr]);
  }, [model?.errors?.code, model?.errors?.name, model?.errors?.role]);

  return (
    <Modal
      size={1024}
      visibleFooter={true}
      visible={visible}
      title={translate("workflowSteps.titleModal")}
      handleCancel={handleClose}
      handleSave={handleSave}
      titleButtonCancel={translate("general.actions.cancel")}
      titleButtonApply={translate("general.actions.save")}
    >
      <Tabs defaultActiveKey="0" tabErrorKeys={listTabError}>
        <TabPane
          key="tab-general"
          tab={translate("workflowSteps.tabs.generalConfig")}
          className="p-t--xs p-l--xxs"
        >
          <Row>
            <Col lg={12} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.code)
                )}
                message={model.errors?.code}
              >
                <InputText
                  isRequired
                  label={translate("workflowSteps.code")}
                  type={0}
                  value={model.code}
                  placeHolder={translate("workflowSteps.placeholder.code")}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeSingleField({
                    fieldName: nameof(model.code),
                  })}
                />
              </FormItem>
            </Col>

            <Col lg={12} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.name)
                )}
                message={model.errors?.name}
              >
                <InputText
                  isRequired
                  label={translate("workflowSteps.name")}
                  type={0}
                  value={model.name}
                  placeHolder={translate("workflowSteps.placeholder.name")}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeSingleField({
                    fieldName: nameof(model.name),
                  })}
                />
              </FormItem>
            </Col>

            <Col lg={12} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.role)
                )}
                message={model.errors?.role}
              >
                <Select
                  isRequired
                  label={translate("workflowSteps.role")}
                  type={0}
                  classFilter={RoleFilter}
                  searchProperty={"name"}
                  placeHolder={translate("workflowSteps.placeholder.role")}
                  getList={workflowStepRepository.singleListRole}
                  onChange={handleChangeSelectField({
                    fieldName: nameof(model.role),
                  })}
                  valueFilter={{
                    ...new RoleFilter(),
                    siteId: { equal: model?.workflowDefinition?.siteId },
                  }}
                  value={model.role}
                  disabled={!model?.workflowDefinitionId}
                  appendToBody
                />
              </FormItem>
            </Col>

            <Col lg={12} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.status)
                )}
                message={model.errors?.status}
              >
                <div>
                  <div className="label-status">
                    {translate("workflowSteps.status")}
                  </div>
                  <Switch
                    checked={model.statusId === 1 ? true : false}
                    onChange={handleChangeStatus}
                    className="switch_status"
                  />
                </div>
              </FormItem>
            </Col>
          </Row>
        </TabPane>
        <TabPane key="1" tab={translate("workflowSteps.tabs.mailConfig")}>
          <Row className="m-t--xs">
            <Col span={12} className="p-l--xxxs p-r--sm">
              <div className="page-detail__title p-b--xxs">
                {translate("workflowSteps.titles.mailForRejectStep")}
              </div>
              <Row>
                <Col lg={24}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.subjectMailForReject)
                    )}
                    message={model.errors?.subjectMailForReject}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowSteps.subjectMail")}
                      value={model.subjectMailForReject}
                      placeHolder={translate(
                        "workflowSteps.placeholder.subjectMail"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.subjectMailForReject),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>
                <Col lg={24} className={`m-t--xs `}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.bodyMailForReject)
                    )}
                    message={model.errors?.bodyMailForReject}
                  >
                    <Editor
                      value={model.bodyMailForReject}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.bodyMailForReject),
                      })}
                      placeholder={translate(
                        "workflowSteps.placeholder.content"
                      )}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <div className="page-detail__title p-b--xxs">
                {translate("workflowSteps.titles.mailForTerminate")}
              </div>
              <Row>
                <Col lg={24} className="">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.subjectMailForTerminate)
                    )}
                    message={model.errors?.subjectMailForTerminate}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowSteps.subjectMail")}
                      isMaterial={true}
                      value={model.subjectMailForTerminate}
                      placeHolder={translate(
                        "workflowSteps.placeholder.subjectMail"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.subjectMailForTerminate),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>
                <Col lg={24} className="m-t--xs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model.errors,
                      nameof(model.bodyMailForTerminate)
                    )}
                    message={model.errors?.bodyMailForTerminate}
                  >
                    <Editor
                      value={model.bodyMailForTerminate}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.bodyMailForTerminate),
                      })}
                      placeholder={translate(
                        "workflowSteps.placeholder.content"
                      )}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane key="2" tab={translate("workflowSteps.tabs.notiConfig")}>
          <Row className="m-t--xs">
            <Col span={12} className="p-l--xxxs p-r--sm">
              <div className="page-detail__title p-b--xxs">
                {translate("workflowSteps.titles.notificationForReject")}
              </div>
              <Row>
                <Col lg={24}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.titleNotificationForReject)
                    )}
                    message={model.errors?.titleNotificationForReject}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowSteps.titleNotification")}
                      value={model.titleNotificationForReject}
                      placeHolder={translate(
                        "workflowSteps.placeholder.titleNotification"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.titleNotificationForReject),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>

                <Col lg={24} className="m-t--xs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.contentNotificationForReject)
                    )}
                    message={model.errors?.contentNotificationForReject}
                  >
                    <textarea
                      id="noti_reject"
                      name="noti_reject"
                      onChange={handleChangeNotificationContent(
                        "contentNotificationForReject"
                      )}
                      value={
                        model.contentNotificationForReject
                          ? model.contentNotificationForReject
                          : ""
                      }
                      className="w-100"
                      placeholder={translate(
                        "workflowSteps.placeholder.content"
                      )}
                    ></textarea>
                  </FormItem>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <div className="page-detail__title p-b--xxs">
                {translate("workflowSteps.titles.notificationForTerminate")}
              </div>
              <Row>
                <Col lg={24}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.titleNotificationForTerminate)
                    )}
                    message={model.errors?.titleNotificationForTerminate}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowSteps.titleNotification")}
                      isMaterial={true}
                      value={model.titleNotificationForTerminate}
                      placeHolder={translate(
                        "workflowSteps.placeholder.titleNotification"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.titleNotificationForTerminate),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>
                <Col lg={24} className="m-t--xs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model.errors,
                      nameof(model.contentNotificationForTerminate)
                    )}
                    message={model.errors?.contentNotificationForTerminate}
                  >
                    <textarea
                      id="noti_terminate"
                      name="noti_terminate"
                      onChange={handleChangeNotificationContent(
                        "contentNotificationForTerminate"
                      )}
                      value={
                        model.contentNotificationForTerminate
                          ? model.contentNotificationForTerminate
                          : ""
                      }
                      className="w-100"
                      placeholder={translate(
                        "workflowSteps.placeholder.content"
                      )}
                    ></textarea>
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default WorkflowStepDetailModal;
