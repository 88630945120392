/* begin general import */
import Add16 from "@carbon/icons-react/es/add/16";
import { Row } from "antd";
/* end general import */
/* begin individual import */
import { Role } from "models/Role";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, StandardTable } from "react3l-ui-library";
import { ModelAction } from "services/page-services/detail-service";
import nameof from "ts-nameof.macro";
import { useGeneralFieldHook } from "./GeneralFieldHook";
/* end individual import */

interface PermissionGeneralFieldProps {
  model: Role;
  setModel?: (data: Role) => void;

  dispatchModel?: React.Dispatch<ModelAction<Role>>;
}

function PermissionGeneralField(props: PermissionGeneralFieldProps) {
  const [translate] = useTranslation();

  const { model, dispatchModel } = props;

  const { generalFields, generalFieldColumns, handleAddGeneralField } =
    useGeneralFieldHook(model, dispatchModel);

  return (
    <div>
      <Row className="role-content-table">
        <div>
          <StandardTable
            rowKey={nameof(generalFields[0].key)}
            columns={generalFieldColumns}
            dataSource={generalFields}
            tableSize={"lg"}
            scroll={{ y: 450 }}
          />
        </div>
      </Row>
      <Row>
        <div className="action__container mt-2">
          <Button
            type="primary"
            className="btn--lg"
            icon={<Add16 />}
            onClick={handleAddGeneralField}
          >
            {translate("general.button.add")}
          </Button>
        </div>
      </Row>
    </div>
  );
}

export default PermissionGeneralField;
