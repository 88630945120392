import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { ColumnProps } from "antd/lib/table";
import { renderMasterIndex } from "helpers/table";
import { WorkflowDirection } from "models/WorkflowDirection";
import {
  WorkflowDirectionCondition,
  WorkflowDirectionConditionFilter,
} from "models/WorkflowDirectionCondition";
import { WorkflowOperatorFilter } from "models/WorkflowOperator";
import { WorkflowParameterFilter } from "models/WorkflowParameter";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormItem,
  LayoutCell,
  LayoutHeader,
  Select,
} from "react3l-ui-library";
import { workflowDirectionRepository } from "repositories/workflow-direction-repository";
import { utilService } from "services/common-services/util-service";
import { fieldService } from "services/page-services/field-service";
import {
  FilterAction,
  filterReducer,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { tableService } from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import FieldInput from "./FieldInput/FieldInput";

export function useWorkflowDirectionConditionTableHook(
  model: WorkflowDirection,
  dispatch: any
) {
  const [translate] = useTranslation();

  const [filter, dispatchFilter] = React.useReducer<
    React.Reducer<
      WorkflowDirectionConditionFilter,
      FilterAction<WorkflowDirectionConditionFilter>
    >
  >(filterReducer, new WorkflowDirectionConditionFilter());

  const { handleChangeListField } = fieldService.useField(model, dispatch);

  const { list, count } = listService.useLocalList(
    model?.workflowDirectionConditions,
    filter
  );

  const {
    // handleResetTable,
    // handlePagination,
    // handleChangeCell,
    handleChangeRow,
    handleAddRow,
    handleDeleteRow,
  } = tableService.useContentTable(
    WorkflowDirectionConditionFilter,
    WorkflowDirectionCondition,
    list,
    handleChangeListField({ fieldName: "workflowDirectionConditions" }),
    dispatchFilter
  );

  // const handleChangeField = React.useCallback(
  //   (params, fieldName) => (value: string) => {
  //     handleChangeCell(params[1]?.key, fieldName, value);
  //   },
  //   [handleChangeCell]
  // );

  const handleChangeWorkflowType = React.useCallback(
    (params, fieldName) => (id: number, value: any) => {
      const newValue = {
        ...params[1],
        [fieldName]: { ...value },
        [`${fieldName}Id`]: id,
        value: undefined,
      };
      handleChangeRow(params[1]?.key, newValue);
    },
    [handleChangeRow]
  );

  // const handleChangeWorkflowType = React.useCallback(
  //   (index: number) => (value: any, object?: any) => {
  //     const newValue = [...list];
  //     newModel[index]["workflowParameter"] = object;
  //     newModel[index]["workflowParameterId"] = value;
  //     newModel[index]["value"] = undefined;

  //     handleChangeRow(params[1]?.key, newValue);
  //   },
  //   [handleChangeListField, list]
  // );
  // const handleChangeworkflowOperator = React.useCallback(
  //   (index: number) => (value: any, object?: any) => {
  //     const newList: any[] = [...list];
  //     newList[index]["workflowOperator"] = object;
  //     newList[index]["workflowOperatorId"] = value;
  //     handleChangeListField({ fieldName: "workflowDirectionConditions" });
  //   },
  //   [list, handleChangeListField]
  // );

  const handleDeleteOneRow = React.useCallback(
    (content) => (event: any) => {
      handleDeleteRow(content?.key);
    },
    [handleDeleteRow]
  );

  const columns: ColumnProps<WorkflowDirection>[] = useMemo(() => {
    return [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
          />
        ),

        key: "index",
        width: 80,
        align: "center",
        render: renderMasterIndex<WorkflowDirection>(),
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "workflowDirections.workflowDirectionConditions.workflowParameter"
            )}
          />
        ),
        key: nameof(list[0].workflowParameter),
        dataIndex: nameof(list[0].workflowParameter),

        ellipsis: true,
        render(...params: [string, WorkflowDirectionCondition, number]) {
          const workflowParameterFilter = new WorkflowParameterFilter();
          workflowParameterFilter.workflowTypeId.equal = model?.workflowTypeId;
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  list[params[2]],
                  nameof(list[params[2]].workflowParameterId)
                )}
                message={list[params[2]].errors?.workflowParameterId}
              >
                <Select
                  isMaterial={true}
                  classFilter={WorkflowParameterFilter}
                  placeHolder={translate(
                    "workflowDirections.workflowDirectionConditions.placeholder.workflowParameter"
                  )}
                  getList={
                    workflowDirectionRepository.singleListWorkflowParameter
                  }
                  onChange={handleChangeWorkflowType(
                    params,
                    "workflowParameter"
                  )}
                  value={params[1].workflowParameter}
                  disabled={model.workflowDefinitionId ? false : true}
                  valueFilter={workflowParameterFilter}
                  appendToBody
                  type={0}
                />
              </FormItem>
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "workflowDirections.workflowDirectionConditions.workflowOperator"
            )}
          />
        ),
        key: nameof(list[0].workflowOperator),
        dataIndex: nameof(list[0].workflowOperator),

        ellipsis: true,
        render(...params: [string, WorkflowDirectionCondition, number]) {
          const workflowOperatorFilter = new WorkflowOperatorFilter();
          workflowOperatorFilter.workflowParameterTypeId.equal =
            params[1]?.workflowParameter?.workflowParameterTypeId;
          return (
            <LayoutCell orderType="left" tableSize="lg">
              {list[params[2]]?.workflowParameterId &&
              list[params[2]]?.workflowParameter ? (
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    list[params[2]],
                    nameof(list[params[2]].workflowOperator)
                  )}
                  message={list[params[2]].errors?.workflowOperator}
                >
                  <Select
                    isMaterial={true}
                    classFilter={WorkflowOperatorFilter}
                    valueFilter={workflowOperatorFilter}
                    placeHolder={translate(
                      "workflowDirections.workflowDirectionConditions.placeholder.workflowOperator"
                    )}
                    getList={
                      workflowDirectionRepository.singleListWorkflowOperator
                    }
                    onChange={handleChangeWorkflowType(
                      params,
                      "workflowOperator"
                    )}
                    value={params[1].workflowOperator}
                    disabled={model.workflowDefinitionId ? false : true}
                    appendToBody
                    type={0}
                  />
                </FormItem>
              ) : (
                <></>
              )}
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "workflowDirections.workflowDirectionConditions.value"
            )}
          />
        ),
        key: nameof(list[0].value),
        dataIndex: nameof(list[0].value),
        ellipsis: true,
        render(...params: [string, WorkflowDirectionCondition, number]) {
          const workflowOperatorFilter = new WorkflowOperatorFilter();
          workflowOperatorFilter.workflowParameterTypeId.equal =
            params[1]?.workflowParameter?.workflowParameterTypeId;
          return (
            <LayoutCell orderType="left" tableSize="lg">
              {list[params[2]]?.workflowOperatorId &&
              list[params[2]]?.workflowParameterId &&
              list[params[2]]?.workflowOperator &&
              list[params[2]]?.workflowParameter ? (
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    list[params[2]],
                    nameof(list[params[2]].value)
                  )}
                  message={list[params[2]].errors?.value}
                >
                  <FieldInput
                    value={params[0]}
                    index={params[2]}
                    record={params[1]}
                    contents={list}
                    setContents={handleChangeListField}
                    disabled={
                      params[1].workflowOperatorId === undefined ||
                      params[1].workflowOperatorId === 0 ||
                      typeof params[1].errors?.workflowOperatorId ===
                        "string" ||
                      model.used
                    }
                    type={params[1]?.workflowParameter?.workflowParameterTypeId}
                  />
                </FormItem>
              ) : (
                <></>
              )}
            </LayoutCell>
          );
        },
      },
      {
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 150,
        align: "center",
        render(...params: [string, WorkflowDirectionCondition, number]) {
          return (
            // <TrashCan16
            //   color="red"
            //   onClick={() => handleLocalDelete(params[1])}
            // />
            <LayoutCell orderType="left" tableSize="md">
              <Button
                className=""
                type="icon-only-ghost"
                icon={<TrashCan16 />}
                onClick={handleDeleteOneRow(params[1])}
              ></Button>
            </LayoutCell>
          );
        },
      },
    ];
  }, [
    handleChangeListField,
    handleChangeWorkflowType,
    handleDeleteOneRow,
    list,
    model.used,
    model.workflowDefinitionId,
    model?.workflowTypeId,
    translate,
  ]);

  return {
    filter,
    list,
    count,
    columns,
    handleAddRow,
  };
}
