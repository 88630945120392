
import {
    DateFilter,
    GuidFilter,
    IdFilter,
    NumberFilter,
    StringFilter,
  } from "react3l-advanced-filters";
  import { ModelFilter } from "react3l-common";
  
  export class AppUserGroupingFilter extends ModelFilter {
    public id?: IdFilter = new IdFilter();
    public code?: StringFilter = new StringFilter();
    public name?: StringFilter = new StringFilter();
    public search?: string;
    public parentId?: IdFilter = new IdFilter();
    public path?: StringFilter = new StringFilter();
    public level?: NumberFilter = new NumberFilter();
    public statusId?: IdFilter = new IdFilter();
    public createdAt?: DateFilter = new DateFilter();
    public updatedAt?: DateFilter = new DateFilter();
    public rowId?: GuidFilter = new GuidFilter();
    public dependencyTypeId?: IdFilter = new IdFilter();
    public organizationId?: IdFilter = new IdFilter();
  }
  