import { translate } from "i18n/i18n";
import { TFunction } from "i18next";
import { ReactNode } from "react";
import {
  APP_USER_GROUPING_MASTER_ROUTE,
  APP_USER_MASTER_ROUTE,
  DISTRICT_MASTER_ROUTE,
  INIT_MASTER_ROUTE,
  LOGIN_CONFIGURATION_MASTER_ROUTE,
  MENU_REPORT_MASTER_ROUTE,
  NATION_MASTER_ROUTE,
  ORGANIZATION_MASTER_ROUTE,
  POSITION_MASTER_ROUTE,
  PROVINCE_MASTER_ROUTE,
  REGION_MASTER_ROUTE,
  ROLE_MASTER_ROUTE,
  SITE_MASTER_ROUTE,
  WARD_MASTER_ROUTE,
  WORKFLOW_DEFINITION_MASTER_ROUTE,
  WORKFLOW_DIRECTION_MASTER_ROUTE,
  WORKFLOW_PARAMETER_MASTER_ROUTE,
  WORKFLOW_STEP_MASTER_ROUTE,
} from "./route-consts";

export interface Menu {
  name?: string | TFunction;
  icon?: string | ReactNode;
  link: string;
  children?: Menu[];
  active?: boolean;
  show?: boolean;
  checkVisible?: (mapper: Record<string, number>) => boolean;
}

export const menu: Menu[] = [
  {
    name: translate("menu.title.appUser"),
    icon: "bx-user",
    link: "/app-user",
    active: false,
    show: true,
    children: [
      {
        name: translate("menu.title.appUser"),
        link: APP_USER_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.title.appUserGrouping"),
        link: APP_USER_GROUPING_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.title.role"),
        link: ROLE_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.title.organization"),
        link: ORGANIZATION_MASTER_ROUTE,
        active: false,
        show: true,
      },
    ],
  },
  {
    name: translate("menu.workflows"),
    icon: "bx-network-chart",
    link: "/workflows",
    show: true,
    active: false,
    children: [
      {
        name: translate("menu.workflowDefinitions"),
        link: WORKFLOW_DEFINITION_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.workflowDirection"),
        link: WORKFLOW_DIRECTION_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.workflowSteps"),
        link: WORKFLOW_STEP_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.workflowParameters"),
        link: WORKFLOW_PARAMETER_MASTER_ROUTE,
        active: false,
        show: true,
      },
    ],
  },
  {
    name: translate("menu.title.aministrativeDivisions"),
    icon: "bx-briefcase",
    link: "/aministrative-divisions",
    active: false,
    show: true,
    children: [
      {
        name: translate("menu.title.nation"),
        link: NATION_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.title.region"),
        link: REGION_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.title.province"),
        link: PROVINCE_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.title.district"),
        link: DISTRICT_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.title.ward"),
        link: WARD_MASTER_ROUTE,
        active: false,
        show: true,
      },
    ],
  },
  {
    name: translate("menu.configuration"),
    icon: "bx-wrench",
    link: "/configurations",
    active: false,
    show: true,
    children: [
      {
        name: translate("menu.loginConfiguration"),
        link: LOGIN_CONFIGURATION_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.init"),
        link: INIT_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.position"),
        link: POSITION_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.sites"),
        link: SITE_MASTER_ROUTE,
        active: false,
        show: true,
      },
      {
        name: translate("menu.menuReport"),
        icon: "bx-paint",
        link: MENU_REPORT_MASTER_ROUTE,
        active: false,
        show: true,
      },
    ],
  },
];
