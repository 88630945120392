import { Model } from "react3l-common";
import { Field } from "react3l-decorators";

export class FileTemplateInput extends Model {
  @Field(Number)
  public id: number;
  @Field(String)
  public fieldName: string;
  @Field(String)
  public fieldValue: string;
  @Field(Number)
  public order: number;
}
