import { Status } from "models/Status";
import { Moment } from "moment";
import { Model } from "react3l-common";
import { Field } from "react3l-decorators";


export class AppUserGrouping extends Model {
  @Field(Number)
  public id?: number;

  @Field(String)
  public code?: string;

  @Field(String)
  public name?: string;

  @Field(Number)
  public parentId?: number;

  @Field(String)
  public path?: string;

  @Field(Boolean)
  public hasChildren?: boolean;

  @Field(Number)
  public level?: number;

  @Field(Number)
  public statusId?: number;

  public createdAt?: Moment;

  public updatedAt?: Moment;

  public deletedAt?: Moment;
  @Field(String)
  public rowId?: string;

  public parent?: AppUserGrouping;

  public status?: Status;
}