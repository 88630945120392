import { Col, Row } from "antd";
import { ColumnProps } from "antd/lib/table/Column";
import { TFunction } from "i18next";
import { AppUser, AppUserFilter } from "models/AppUser";
import { AppUserGrouping } from "models/AppUserGrouping";
import { Organization } from "models/Organization";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import {
  AdvanceStringFilter,
  LayoutCell,
  LayoutHeader,
  Modal,
  OneLineText,
  Pagination,
  StandardTable,
} from "react3l-ui-library";
import { appUserGroupingRepository } from "repositories/app-user-grouping-repository";
import { finalize, forkJoin } from "rxjs";
import { webService } from "services/common-services/web-service";
import nameof from "ts-nameof.macro";
import { v4 as uuidv4 } from "uuid";

export function useAppUserGroupingAppUserHook(
  model: AppUserGrouping,
  setModel: Dispatch<SetStateAction<AppUserGrouping>>,
  handleLoadList: (filter: AppUserFilter) => void,
  filterAppUserTable: AppUserFilter,
  listAppUser?: AppUser
) {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [subscription] = webService.useSubscription();
  const [appUserFilter, setAppUserFilter] = React.useState<AppUserFilter>(
    new AppUserFilter()
  );
  const [list, setList] = React.useState<AppUser[]>([]);
  const [total, setTotal] = React.useState<number>(undefined);
  const [loading, setLoading] = React.useState<boolean>();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<KeyType[]>([]);
  const [selectedList, setSelectedList] = React.useState<AppUser[]>([]);

  // React.useEffect(() => {
  //   if (listAppUser && listAppUser?.length > 0) {
  //     const list: any[] = [];
  //     listAppUser.forEach((item: AppUser) => {
  //       if(!item?.isChild)
  //     });
  //     setSelectedRowKeys([...list]);
  //   } else setSelectedRowKeys([]);
  // }, [listAppUser]);

  const rowSelection = useMemo(
    () => ({
      onChange(selectedRowKeys: KeyType[], selectedRows: AppUser[]) {
        setSelectedList([...selectedRows]);
        setSelectedRowKeys(selectedRowKeys);
      },
      selectedRowKeys,
      type: "checkbox",
    }),
    [selectedRowKeys]
  );
  const handleGetItemList = React.useCallback(
    async (filterValue) => {
      setLoading(true);
      const newFilter: AppUserFilter = {
        ...filterValue,
        organizationId: {
          equal: model?.organizationId,
        },
        statusId: {
          equal: 1,
        },
      };
      const getNCountItems = forkJoin([
        appUserGroupingRepository.listAppUser(newFilter),
        appUserGroupingRepository.countAppUser(newFilter),
      ])
        .pipe(
          finalize(() => {
            setLoading(false);
          })
        )
        .subscribe(
          (results: [AppUser[], number]) => {
            if (results[0]) {
              setList([...results[0]]);
              setTotal(Number(results[1]));
            }
          },
          (errors: any) => {}
        );
      subscription.add(getNCountItems);
    },
    [model?.organizationId, subscription]
  );

  const handleOpenModal = React.useCallback(
    () => (event: any) => {
      const list: any[] = [];
      if (listAppUser && listAppUser?.length > 0) {
        listAppUser.forEach((item: AppUser) => {
          list.push(item?.id);
        });
      }

      handleGetItemList({
        ...new AppUserFilter(),
        appUserGroupingId: { notIn: [model?.id] },
      });
      setAppUserFilter({
        ...new AppUserFilter(),
        appUserGroupingId: { notIn: [model?.id] },
      });

      setVisible(true);
    },
    [listAppUser, handleGetItemList, model]
  );
  const handleCloseModal = React.useCallback(() => {
    setVisible(false);
  }, []);
  const handleSave = React.useCallback(() => {
    if (selectedList && selectedList.length > 0) {
      const object = selectedRowKeys.map((id) => {
        return {
          appUserGroupingId: model?.id,
          appUserId: id,
          color: "#24a148",
        };
      });
      appUserGroupingRepository.bulkAddAppUser(object).subscribe((res) => {
        handleLoadList(filterAppUserTable);
        handleCloseModal();
        setSelectedRowKeys([]);
      });
    }
  }, [
    filterAppUserTable,
    handleCloseModal,
    handleLoadList,
    model?.id,
    selectedList,
    selectedRowKeys,
  ]);

  const handleFilter = React.useCallback(
    (fieldName: string, filterType: string) => {
      return (value: any) => {
        const newFilter = { ...appUserFilter };
        newFilter[fieldName][filterType] = value;
        newFilter.skip = 0;
        setAppUserFilter({ ...newFilter });
        handleGetItemList(newFilter);
      };
    },
    [appUserFilter, handleGetItemList]
  );

  const handleSelectFilter = React.useCallback(
    (fieldName: string, filterType: string) => (id?: number, value?: any) => {
      const newFilter = { ...appUserFilter };
      newFilter[`${fieldName}Id`][filterType] = id;
      newFilter[`${fieldName}Value`] = value;
      setAppUserFilter({ ...newFilter });
      handleGetItemList(newFilter);
    },
    [appUserFilter, handleGetItemList]
  );

  const handleChangePagination = React.useCallback(
    (skip: number, take: number) => {
      const filterValue = { ...appUserFilter };
      filterValue["skip"] = skip;
      filterValue["take"] = take;
      setAppUserFilter({ ...filterValue });

      handleGetItemList(filterValue);
    },
    [appUserFilter, handleGetItemList]
  );

  return {
    visible,
    list,
    total,
    appUserFilter,
    loading,
    handleOpenModal,
    handleSave,
    handleCloseModal,
    handleChangePagination,
    handleFilter,
    // handleTableChange,
    rowSelection,
    handleSelectFilter,
  };
}
interface AppUserGroupingAppUserMappingModalProps {
  visible: boolean;
  handleClose: () => void;
  handleSave: (data: AppUserGrouping) => void;
  translate: TFunction;
  appUserFilter?: AppUserFilter;
  handleChangeInputFilter?: (
    fieldFilter: string,
    filterType: string
  ) => (value?: any) => void;
  listAppUser?: AppUser[];
  countAppUser?: number;
  loadList?: boolean;
  handleTableChange?: (value: any) => void;
  handlePagination?: (skip: number, take: number) => void;
  rowSelection?: any;
  handleSelectFilter?: (
    fieldFilter: string,
    filterType: string
  ) => (value?: any) => void;
}
export function AppUserGroupingAppUserMappingModal(
  props: AppUserGroupingAppUserMappingModalProps
) {
  const {
    visible,
    handleSave,
    handleClose,
    translate,
    appUserFilter,
    handleChangeInputFilter,
    listAppUser,
    countAppUser,
    loadList,
    handleTableChange,
    handlePagination,
    rowSelection,
  } = props;

  const columns: ColumnProps<AppUser>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.appUsers.organization")}
          />
        ),
        key: nameof(listAppUser[0].organization),
        dataIndex: nameof(listAppUser[0].organization),

        ellipsis: true,
        render(...params: [Organization, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]?.name} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.appUsers.username")}
          />
        ),
        key: nameof(listAppUser[0].username),
        dataIndex: nameof(listAppUser[0].username),

        ellipsis: true,
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.appUsers.displayName")}
          />
        ),
        key: nameof(listAppUser[0].displayName),
        dataIndex: nameof(listAppUser[0].displayName),

        ellipsis: true,
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
    ],
    [translate, listAppUser]
  );

  return (
    <>
      <Modal
        size={720}
        visibleFooter={true}
        visible={visible}
        title={translate("appUserGroupings.appUsers.title")}
        handleSave={handleSave}
        handleCancel={handleClose}
        titleButtonCancel={translate("general.actions.close")}
        titleButtonApply={translate("general.actions.save")}
      >
        <div className="modal-content">
          <Row>
            <Col lg={12} className="m-b--xxs p-r--sm">
              <AdvanceStringFilter
                label={translate("appUserGroupings.appUsers.username")}
                value={appUserFilter["username"]["contain"]}
                onChange={handleChangeInputFilter("username", "contain")}
                placeHolder={translate(
                  "appUserGroupings.appUsers.placeholder.username"
                )}
                type={0}
              />
            </Col>
            <Col lg={12} className="m-b--xxs p-r--sm">
              <AdvanceStringFilter
                label={translate("appUserGroupings.appUsers.displayName")}
                value={appUserFilter["displayName"]["contain"]}
                onChange={handleChangeInputFilter("displayName", "contain")}
                placeHolder={translate(
                  "appUserGroupings.appUsers.placeholder.displayName"
                )}
                type={0}
              />
            </Col>
          </Row>

          <div className=" m-t--sm">
            <StandardTable
              key={uuidv4()}
              rowKey={nameof(listAppUser[0].id)}
              columns={columns}
              dataSource={listAppUser}
              isDragable={true}
              tableSize={"md"}
              onChange={handleTableChange}
              loading={loadList}
              rowSelection={rowSelection}
            />
            <Pagination
              skip={appUserFilter?.skip}
              take={appUserFilter?.take}
              total={countAppUser}
              onChange={handlePagination}
              canChangePageSize={false}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
