import { convertModelDataToDiagramData } from "components/Diagram/helper/convert-data";
import { WorkflowDefinition } from "models/WorkflowDefinition";
import React from "react";

function useWorkflowDefinitionPreview(workflowDefinition: WorkflowDefinition) {
  const diagramData = React.useMemo(() => {
    return convertModelDataToDiagramData(workflowDefinition);
  }, [workflowDefinition]);

  return { diagramData };
}

export default useWorkflowDefinitionPreview;
