import { NumberFilter, StringFilter } from "react3l-advanced-filters";
import { ModelFilter } from "react3l-common";
import { ObjectField } from "react3l-decorators";

export class EnumFilter extends ModelFilter {
  @ObjectField(NumberFilter)
  public id?: NumberFilter = new NumberFilter();
  @ObjectField(StringFilter)
  public code?: StringFilter = new StringFilter();
  @ObjectField(StringFilter)
  public name?: StringFilter = new StringFilter();
}
