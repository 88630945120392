import {
  MENU_REPORT_DETAIL_ROUTE,
  MENU_REPORT_MASTER_ROUTE,
  MENU_REPORT_PREVIEW_ROUTE,
} from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import { authorizationService } from "services/common-services/authorization-service";
import MenuReportDetail from "./MenuReportDetail/MenuReportDetail";
import MenuReportMaster from "./MenuReportMaster/MenuReportMaster";
import { MenuReportPreview } from "./MenuReportPreview/MenuReportPreview";

function MenuReportView() {
  const { auth } = authorizationService.useAuthorizedRoute();
  return (
    <Switch>
      <ProtectedRoute
        path={MENU_REPORT_MASTER_ROUTE}
        key={MENU_REPORT_MASTER_ROUTE}
        component={MenuReportMaster}
        auth={auth(MENU_REPORT_MASTER_ROUTE)}
      />
      <ProtectedRoute
        path={MENU_REPORT_DETAIL_ROUTE}
        key={MENU_REPORT_DETAIL_ROUTE}
        component={MenuReportDetail}
        auth={auth(MENU_REPORT_DETAIL_ROUTE)}
      />
      <ProtectedRoute
        path={MENU_REPORT_PREVIEW_ROUTE}
        key={MENU_REPORT_PREVIEW_ROUTE}
        component={MenuReportPreview}
        auth={auth(MENU_REPORT_PREVIEW_ROUTE)}
      />
    </Switch>
  );
}

export { MenuReportMaster, MenuReportDetail };
export default MenuReportView;
