/* begin general import */
import Add16 from "@carbon/icons-react/es/add/16";
import { Col, Row, Spin, Switch, Tabs as TabAntd } from "antd";
/* end general import */
/* begin individual import */
import { MenuFilter } from "models/Menu";
import { Permission } from "models/Permission";
import { Role } from "models/Role";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  DatePicker,
  FormItem,
  InputText,
  Modal,
  Select,
  StandardTable,
  Tabs,
} from "react3l-ui-library";
import { roleRepository } from "repositories/role-repository";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import nameof from "ts-nameof.macro";
import {
  usePermisionActionMappingHook,
  usePermisionContentHook,
} from "../RoleDetailHook/PermssionContentHook";
import permissionRoleDetailStyle from "./PermissionRoleDetail.module.scss";
/* end individual import */

const { TabPane } = TabAntd;

interface PermissionRoleDetailModalProps {
  model: Permission;
  setModel?: (data: Permission) => void;
  onChangeSimpleField: (config: { fieldName: string }) => (value: any) => void;
  handleSave?: (value?: any) => void;
  dispatchModel?: React.Dispatch<ModelAction<Role>>;
  loading?: boolean;
  role?: Role;
  visible?: boolean;
  handleCancel?: () => void;
  visibleFooter?: boolean;
  siteId?: number;
  isCreatePermission?: boolean;
}

function PermissionRoleDetailModal(props: PermissionRoleDetailModalProps) {
  const [translate] = useTranslation();

  const {
    model,
    onChangeSimpleField,
    loading,
    setModel,
    siteId,
    dispatchModel,
    role,
    isCreatePermission,
  } = props;

  const [listTabError, setListTabError] = React.useState<string[]>([]);

  React.useEffect(() => {
    let tmpListTabErr: string[] = [];

    // check error tab infomation
    if (model?.errors?.code || model?.errors?.name || model?.errors?.menuId) {
      tmpListTabErr.push("tabInformation");
    } else if (
      !(model?.errors?.code || model?.errors?.name || model?.errors?.menuId) &&
      tmpListTabErr?.length > 0
    ) {
      const tmpList = [...tmpListTabErr];
      tmpListTabErr = tmpList.filter((e) => e !== "tabInformation");
    }

    // check error tab field
    if (model?.errors?.permissionContents) {
      tmpListTabErr.push("tabContent");
    } else if (
      !model?.errors?.permissionContents &&
      tmpListTabErr?.length > 0
    ) {
      const tmpList = [...tmpListTabErr];
      tmpListTabErr = tmpList.filter((e) => e !== "tabContent");
    }

    // check error tab action
    if (model?.errors?.permissionActionMapping) {
      tmpListTabErr.push("tabAction");
    } else if (
      !model?.errors?.permissionActionMapping &&
      tmpListTabErr?.length > 0
    ) {
      const tmpList = [...tmpListTabErr];
      tmpListTabErr = tmpList.filter((e) => e !== "tabAction");
    }
    setListTabError([...tmpListTabErr]);
  }, [
    model?.errors?.code,
    model?.errors?.menuId,
    model?.errors?.name,
    model?.errors?.permissionActionMapping,
    model?.errors?.permissionContents,
  ]);

  const handleChangeStatus = React.useCallback(
    (checked) => {
      const newModel = { ...model };
      if (checked) {
        newModel.statusId = 1;
      } else {
        newModel.statusId = 0;
      }
      setModel(newModel);
    },
    [setModel, model]
  );

  const [loadPermissionDraft, setLoadPermissionDraft] =
    React.useState<boolean>(false);
  const [loadMenu, setLoadMenu] = React.useState<boolean>(false);
  // const [isLoadedDraft,]

  React.useEffect(() => {
    if (isCreatePermission && model.menuId && loadPermissionDraft) {
      setLoadPermissionDraft(false);
      const currentMenu = model.menu;
      const currentModel = { ...model };
      roleRepository
        .getPermissionDraft({
          ...currentModel,
          menuId: currentModel.menuId,
          roleId: role.id,
        })
        .subscribe((res: Permission) => {
          setModel({
            ...currentModel,
            menu: currentMenu,
            menuId: currentMenu.id,
            permissionActionMappings: res.permissionActionMappings,
            permissionContents: res.permissionContents,
          });
          setTimeout(() => {
            setLoadMenu(true);
          }, 500);
        });
    }
  }, [
    isCreatePermission,
    loadPermissionDraft,
    model,
    model.menu,
    model.menuId,
    role.id,
    setModel,
  ]);

  const handleChangeMenu = React.useCallback(
    () => (idValue: number, value: any) => {
      const newModel = { ...model };
      newModel["menu"] = value;
      newModel["menuId"] = idValue;
      newModel["permissionActionMappings"] = undefined;
      newModel["permissionContents"] = undefined;
      setModel(newModel);
      if (isCreatePermission) {
        setLoadPermissionDraft(true);
      }
    },
    [isCreatePermission, model, setModel]
  );
  const {
    // filter: permissionActionFilter,
    permissionActionMappingList,
    // loadPermissionActionMappingList,
    permissionActionMappingColumns,
    permissionActionMappingRowSelection,
  } = usePermisionActionMappingHook(
    model,
    dispatchModel,
    loadMenu,
    setLoadMenu,
    isCreatePermission
  );

  const {
    permissionContents,
    handleAddPermissionContent,
    permissionContentColumns,
  } = usePermisionContentHook(model, dispatchModel);

  return (
    <Modal
      {...props}
      width={1200}
      titleButtonCancel={translate("general.actions.close")}
      titleButtonApply={translate("general.actions.save")}
    >
      <Spin spinning={loading}>
        <div className={permissionRoleDetailStyle["permission_tabs"]}>
          <div className="page__modal-header w-100">
            <Row className="d-flex">
              <Col
                lg={24}
                className={`${permissionRoleDetailStyle["permission-role-detail_header_tile"]} page__modal-header-title`}
              >
                {model?.id ? (
                  <div className="page__title mr-1">
                    {translate("permissions.detail.title")}
                  </div>
                ) : (
                  translate("permissions.detail.create")
                )}
              </Col>
            </Row>
          </div>
          <Tabs defaultActiveKey="1" tabErrorKeys={listTabError}>
            <TabPane
              key="tabInformation"
              tab={translate("permissions.titleInfor")}
            >
              <Row className="m-t--xxs">
                <Col lg={12} className="m-b--xxs p-r--sm  p-l--xxxs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.code)
                    )}
                    message={model.errors?.code}
                  >
                    <InputText
                      isRequired
                      label={translate("permissions.code")}
                      type={0}
                      value={model.code}
                      placeHolder={translate("permissions.placeholder.code")}
                      className={"tio-account_square_outlined"}
                      onChange={onChangeSimpleField({
                        fieldName: nameof(model.code),
                      })}
                    />
                  </FormItem>
                </Col>
                <Col lg={12} className="m-b--xxs p-r--sm">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.name)
                    )}
                    message={model.errors?.name}
                  >
                    <InputText
                      isRequired
                      label={translate("permissions.name")}
                      value={model.name}
                      type={0}
                      placeHolder={translate("permissions.placeholder.name")}
                      className={"tio-account_square_outlined"}
                      onChange={onChangeSimpleField({
                        fieldName: nameof(model.name),
                      })}
                    />
                  </FormItem>
                </Col>

                <Col lg={12} className="m-b--xxs p-r--sm p-l--xxxs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.startAt)
                    )}
                    message={model.errors?.startAt}
                  >
                    <DatePicker
                      label={translate("permissions.startAt")}
                      value={model.startAt}
                      type={0}
                      onChange={onChangeSimpleField({
                        fieldName: nameof(model.startAt),
                      })}
                      placeholder={translate("permissions.placeholder.startAt")}
                    />
                  </FormItem>
                </Col>
                <Col lg={12} className="m-b--xxs p-r--sm">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.endAt)
                    )}
                    message={model.errors?.endAt}
                  >
                    <DatePicker
                      label={translate("permissions.endAt")}
                      value={model.endAt}
                      type={0}
                      onChange={onChangeSimpleField({
                        fieldName: nameof(model.endAt),
                      })}
                      placeholder={translate("permissions.placeholder.endAt")}
                    />
                  </FormItem>
                </Col>
                <Col lg={12} className="m-b--xxs p-r--sm p-l--xxxs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.menu)
                    )}
                    message={model.errors?.menu}
                  >
                    <Select
                      isRequired
                      type={0}
                      label={translate("permissions.menu")}
                      classFilter={MenuFilter}
                      placeHolder={translate("permissions.placeholder.menu")}
                      getList={roleRepository.singleListMenu}
                      onChange={handleChangeMenu()}
                      valueFilter={{
                        ...new MenuFilter(),
                        siteId: { equal: siteId },
                      }}
                      value={model.menu}
                      appendToBody={true}
                    />
                  </FormItem>
                </Col>
                <Col lg={12} className="m-b--xxs p-r--sm">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.status)
                    )}
                    message={model.errors?.status}
                  >
                    <div>
                      <div className="label-status">
                        {translate("permissions.status")}
                      </div>
                      <Switch
                        checked={model.statusId === 1 ? true : false}
                        onChange={handleChangeStatus}
                        className="switch_status"
                      />
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </TabPane>
            {model.menuId ? (
              <>
                <TabPane key="tabContent" tab={translate("permissions.field")}>
                  <Row className="role-content-table m-t--sm">
                    <div
                      className={
                        permissionRoleDetailStyle["table-content-role-content"]
                      }
                    >
                      <StandardTable
                        rowKey={nameof(permissionContents[0].key)}
                        columns={permissionContentColumns}
                        dataSource={permissionContents}
                        tableSize={"lg"}
                        scroll={{ y: 450 }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="action__container mt-2">
                      <Button
                        type="primary"
                        className="btn--lg"
                        icon={<Add16 />}
                        onClick={handleAddPermissionContent}
                      >
                        {translate("general.button.add")}
                      </Button>
                    </div>
                  </Row>
                </TabPane>
                <TabPane key="tabAction" tab={translate("permissions.action")}>
                  <Row
                    className={
                      permissionRoleDetailStyle["role-content-table-action"]
                    }
                  >
                    {" "}
                    <div
                      className={
                        permissionRoleDetailStyle["table-content-role-action"]
                      }
                    >
                      <Col lg={24} className="m-t--sm">
                        <StandardTable
                          rowKey={nameof(permissionActionMappingList[0].key)}
                          columns={permissionActionMappingColumns}
                          dataSource={permissionActionMappingList}
                          tableSize={"lg"}
                          rowSelection={permissionActionMappingRowSelection}
                          scroll={{ y: 450 }}
                        />
                        <FormItem
                          validateStatus={utilService.getValidateStatus(
                            model,
                            nameof(model.permissionActionMapping)
                          )}
                          message={model.errors?.permissionActionMapping}
                        >
                          {""}
                        </FormItem>
                      </Col>
                    </div>
                  </Row>
                </TabPane>
              </>
            ) : (
              <></>
            )}
          </Tabs>
        </div>
      </Spin>
    </Modal>
  );
}

export default PermissionRoleDetailModal;
