// import RightSidebar from "components/CommonForBoth/RightSidebar/RightSidebar";
import NotificationDropdown from "components/CommonForBoth/TopbarDropdown/NotificationDropdown/NotificationDropdown";
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu/ProfileMenu";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "react-drawer/lib/react-drawer.css";
//i18n
import { Close20, Switcher20 } from "@carbon/icons-react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { showRightSidebarAction, toggleLeftmenu } from "store/actions";
import LandingPageDrawer from "views/LandingPageView/LandingPageDrawer/LandingPageDrawer";
import Navbar from "../Navbar/Navbar";
import "./Header.module.scss";
import headerStyle from "./Header.module.scss";
import { AppUserSiteMapping } from "./HeaderHook";

interface Document extends HTMLDocument {
  mozFullScreenElement: any;
  webkitFullscreenElement: any;
  mozRequestFullScreen: any;
  webkitRequestFullscreen: any;
  cancelFullScreen: () => void;
  mozCancelFullScreen: () => void;
  webkitCancelFullScreen: () => void;
}

const Header = (props: any) => {
  const [isSearch, setSearch] = useState(false);

  const [isShowDrawer, setIsShowDrawer] = React.useState<boolean>(false);
  const [showD, setShowD] = React.useState<boolean>(false);

  const currentUserInfo = JSON.parse(localStorage.getItem("currentUserInfo"));

  const currentSite = React.useMemo(() => {
    if (
      currentUserInfo &&
      currentUserInfo?.appUserSiteMappings &&
      currentUserInfo?.appUserSiteMappings.length > 0
    ) {
      return currentUserInfo.appUserSiteMappings.filter(
        (item: AppUserSiteMapping) => {
          return item.site.code.includes(process.env.PUBLIC_URL);
        }
      )[0]?.site;
    }
  }, [currentUserInfo]);

  const handleClickLandingDrawer = React.useCallback(() => {
    if (isShowDrawer) {
      setIsShowDrawer(false);
    } else {
      setShowD(true);
      setTimeout(() => {
        setIsShowDrawer(true);
      }, 1);
    }
  }, [isShowDrawer]);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !(document as Document)
        .mozFullScreenElement &&
      !(document as Document).webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      if ((document as Document).cancelFullScreen) {
        (document as Document).cancelFullScreen();
      } else if ((document as Document).mozCancelFullScreen) {
        (document as Document).mozCancelFullScreen();
      } else if ((document as Document).webkitCancelFullScreen) {
        (document as Document).webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex navbar-header--inner align-items-center">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={currentSite?.icon} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={currentSite?.icon} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={currentSite?.icon} alt="" height="22" />
                  {/* {currentSite?.name} */}
                </span>
                <span className="logo-lg">
                  <img src={currentSite?.icon} alt="" height="19" />
                  {/* {currentSite?.name} */}
                </span>
              </Link>

              <span
                className={classNames(
                  headerStyle["header__brand__name"],
                  "navbar-brand-name"
                )}
              >
                Portal.IO
              </span>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <div className="navbar-menu navbar-menu--sm">
              <Navbar />
            </div>
          </div>

          <div className="d-flex navbar-header--inner align-items-center">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className={headerStyle["button-header"]}>
              <button
                type="button"
                className={`btn noti-icon ${headerStyle["button-header-full-screen"]}`}
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <NotificationDropdown />

            <ProfileMenu />

            <div
              onClick={() => handleClickLandingDrawer()}
              className={classNames(headerStyle["button-ladding-drawer"])}
            >
              {!isShowDrawer ? (
                <Switcher20 color={"#111111"} />
              ) : (
                <Close20 color={"#111111"} />
              )}
            </div>
          </div>
        </div>
      </header>
      <LandingPageDrawer
        isShowDrawer={isShowDrawer}
        setIsShowDrawer={setIsShowDrawer}
        displayDrawer={showD}
        setDisplayDrawer={setShowD}
      />
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state: any) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
