import { AxiosResponse } from "axios";
import { BASE_API_URL } from "config/consts";
import { httpConfig } from "config/http";
import { kebabCase } from "lodash";
import { Repository } from "react3l-common";
import { Observable } from "rxjs";

import nameof from "ts-nameof.macro";

import { API_APP_USER_GROUPING_PREFIX } from "config/api-consts";
import { AppUser, AppUserFilter } from "models/AppUser";
import {
  AppUserGrouping,
  AppUserGroupingFilter,
} from "models/AppUserGrouping";
import { District, DistrictFilter } from "models/District";
import { Province, ProvinceFilter } from "models/Province";
import { Status, StatusFilter } from "models/Status";
import { Ward, WardFilter } from "models/Ward";
import { Organization, OrganizationFilter } from "models/Organization";

export type KeyType = string | number;

export class AppUserGroupingRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = new URL(API_APP_USER_GROUPING_PREFIX, BASE_API_URL).href;
  }

  public count = (
    filter?: AppUserGroupingFilter
  ): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.count)), filter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public list = (
    filter?: AppUserGroupingFilter
  ): Observable<AppUserGrouping[]> => {
    return this.http
      .post<AppUserGrouping[]>(kebabCase(nameof(this.list)), filter)
      .pipe(Repository.responseMapToList<AppUserGrouping>(AppUserGrouping));
  };

  public countAppUser = (
    filter?: AppUserFilter
  ): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.countAppUser)), filter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public listAppUser = (
    filter?: AppUserFilter
  ): Observable<AppUser[]> => {
    return this.http
      .post<AppUser[]>(kebabCase(nameof(this.listAppUser)), filter)
      .pipe(Repository.responseMapToList<AppUser>(AppUser));
  };

  public get = (id: number | string): Observable<AppUserGrouping> => {
    return this.http
      .post<AppUserGrouping>(kebabCase(nameof(this.get)), { id })
      .pipe(Repository.responseMapToModel<AppUserGrouping>(AppUserGrouping));
  };

  public create = (
    data: AppUserGrouping
  ): Observable<AppUserGrouping> => {
    return this.http
      .post<AppUserGrouping>(kebabCase(nameof(this.create)), data)
      .pipe(Repository.responseMapToModel<AppUserGrouping>(AppUserGrouping));
  };

  public update = (
    data: AppUserGrouping
  ): Observable<AppUserGrouping> => {
    return this.http
      .post<AppUserGrouping>(kebabCase(nameof(this.update)), data)
      .pipe(Repository.responseMapToModel<AppUserGrouping>(AppUserGrouping));
  };

  public delete = (
    data: AppUserGrouping
  ): Observable<AppUserGrouping> => {
    return this.http
      .post<AppUserGrouping>(kebabCase(nameof(this.delete)), data)
      .pipe(Repository.responseMapToModel<AppUserGrouping>(AppUserGrouping));
  };

  public bulkDelete = (idList: KeyType[]): Observable<void> => {
    return this.http
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(Repository.responseDataMapper());
  };

  public removeAppUser = (data: AppUser): Observable<AppUser> => {
    return this.http
      .post<AppUser>(kebabCase(nameof(this.removeAppUser)), data)
      .pipe(Repository.responseMapToModel<AppUser>(AppUser));
  };

  public removeAppUserGrouping = (
    data: AppUser
  ): Observable<AppUser> => {
    return this.http
      .post<AppUser>(kebabCase(nameof(this.removeAppUserGrouping)), data)
      .pipe(Repository.responseMapToModel<AppUser>(AppUser));
  };

  public bulkRemoveAppUser = (idList: KeyType[]): Observable<void> => {
    return this.http
      .post(kebabCase(nameof(this.bulkRemoveAppUser)), idList)
      .pipe(Repository.responseDataMapper());
  };

  public save = (data: AppUserGrouping): Observable<AppUserGrouping> => {
    return data.id ? this.update(data) : this.create(data);
  };

  public singleListDistrict = (
    districtFilter: DistrictFilter
  ): Observable<District[]> => {
    return this.http
      .post<District[]>(
        kebabCase(nameof(this.singleListDistrict)),
        districtFilter
      )
      .pipe(Repository.responseMapToList<District>(District));
  };
  public filterListDistrict = (
    districtFilter: DistrictFilter
  ): Observable<District[]> => {
    return this.http
      .post<District[]>(
        kebabCase(nameof(this.filterListDistrict)),
        districtFilter
      )
      .pipe(Repository.responseMapToList<District>(District));
  };
  public singleListProvince = (
    provinceFilter: ProvinceFilter
  ): Observable<Province[]> => {
    return this.http
      .post<Province[]>(
        kebabCase(nameof(this.singleListProvince)),
        provinceFilter
      )
      .pipe(Repository.responseMapToList<Province>(Province));
  };
  public filterListProvince = (
    provinceFilter: ProvinceFilter
  ): Observable<Province[]> => {
    return this.http
      .post<Province[]>(
        kebabCase(nameof(this.filterListProvince)),
        provinceFilter
      )
      .pipe(Repository.responseMapToList<Province>(Province));
  };
  public singleListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.singleListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public filterListOrganization = (filter: OrganizationFilter): Observable<Organization[]> => {
    return this.http
      .post<Organization[]>(
        kebabCase(nameof(this.filterListOrganization)),
        filter
      )
      .pipe(Repository.responseMapToList<Organization>(Organization));
  };


  public singleListAppUserGrouping = (filter: AppUserGroupingFilter): Observable<AppUserGrouping[]> => {
    return this.http
      .post<AppUserGrouping[]>(
        kebabCase(nameof(this.singleListAppUserGrouping)),
        filter
      )
      .pipe(Repository.responseMapToList<AppUserGrouping>(AppUserGrouping));
  };

  public filterListStatus = (
   
  ): Observable<Status[]> => {
    return this.http
      .post<Status[]>(kebabCase(nameof(this.filterListStatus)), new StatusFilter())
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public filterListDependencyType = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.filterListDependencyType)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public singleListWard = (wardFilter: WardFilter): Observable<Ward[]> => {
    return this.http
      .post<Ward[]>(kebabCase(nameof(this.singleListWard)), wardFilter)
      .pipe(Repository.responseMapToList<Ward>(Ward));
  };
  public filterListWard = (wardFilter: WardFilter): Observable<Ward[]> => {
    return this.http
      .post<Ward[]>(kebabCase(nameof(this.filterListWard)), wardFilter)
      .pipe(Repository.responseMapToList<Ward>(Ward));
  };

  public bulkAddAppUser = (data: any): Observable<AppUserGrouping> => {
    return this.http
      .post<AppUserGrouping>(kebabCase(nameof(this.bulkAddAppUser)), data)
      .pipe(Repository.responseMapToModel<AppUserGrouping>(AppUserGrouping));
  };

  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.http
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(Repository.responseDataMapper<any>());
  };

  public export = (filter: any): Observable<AxiosResponse<any>> => {
    return this.http.post("export", filter, {
      responseType: "arraybuffer",
    });
  };

  public exportTemplate = (): Observable<AxiosResponse<any>> => {
    return this.http.post(
      "export-template",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };

  public singleListOrganization = (
    organizationFilter: OrganizationFilter
  ): Observable<Organization[]> => {
    return this.http
      .post<Organization[]>(
        kebabCase(nameof(this.singleListOrganization)),
        organizationFilter
      )
      .pipe(Repository.responseMapToList<Organization>(Organization));
  };

}

export const appUserGroupingRepository = new AppUserGroupingRepository();
