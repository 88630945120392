/* begin general import */
//import SettingsAdjust16 from "@carbon/icons-react/es/settings--adjust/16";
import { Spin } from "antd";
import PageHeader from "components/PageHeader/PageHeader";
import { MenuFilter } from "models/Menu";
import { MenuReportFilter } from "models/MenuReport";
import { SiteFilter } from "models/Site";
import React from "react";
import { IdFilter } from "react3l-advanced-filters";
import {
  AdvanceIdFilterMaster,
  Pagination,
  StandardTable,
  TagFilter,
} from "react3l-ui-library";
/* end general import */
/* begin individual import */
import InputSearch from "react3l-ui-library/build/components/Input/InputSearch";
import { menuReportRepository } from "repositories/menu-report-repository";
import nameof from "ts-nameof.macro";
//import FileTemplateAdvanceFilter from "./MenuReportAdvanceFilter";
import { useMenuReportMasterHook } from "./MenuReportMasterHook";
/* end individual import */

function FileTemplateMaster() {
  const {
    translate,
    handleChangeSelectFilter,
    handleChangeInputSearch,
    handleTableChange,
    handlePagination,
    //handleCancel,
    //setVisible,
    handleChangeAllFilter,
    filter,
    selectedRowKeys,
    columns,
    list,
    count,
    loadingList,
    //visible,
  } = useMenuReportMasterHook();

  return (
    <>
      <Spin spinning={false}>
        <div className="page-content">
          <PageHeader
            title={translate("menuReports.master.subHeader")}
            breadcrumbItems={[
              translate("menuReports.master.header"),
              translate("menuReports.master.subHeader"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("menuReports.master.title")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"menuReports"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>
              {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
                <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                  <div className="page-master__filter d-flex align-items-center justify-content-start">
                    <div className="">
                      <AdvanceIdFilterMaster
                        value={filter[nameof(list[0].siteId)]["equal"]}
                        placeHolder={translate("menuReports.placeholder.site")}
                        classFilter={SiteFilter}
                        onChange={handleChangeSelectFilter({
                          fieldName: nameof(list[0].site),
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        getList={menuReportRepository.filterListSite}
                        label={translate("menuReports.site")}
                      />
                    </div>

                    <div className="">
                      <AdvanceIdFilterMaster
                        value={filter[nameof(list[0].menuId)]["equal"]}
                        placeHolder={translate("menuReports.placeholder.menu")}
                        classFilter={MenuFilter}
                        onChange={handleChangeSelectFilter({
                          fieldName: nameof(list[0].menu),
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        getList={menuReportRepository.filterListMenu}
                        label={translate("menuReports.menu")}
                      />
                    </div>
                  </div>
                  <div className="page-master__filter-action-search d-flex align-items-center">
                    {/* <Button
                      type="icon-only-ghost"
                      icon={<SettingsAdjust16 />}
                      onClick={() => setVisible(true)}
                      className="btn--xl"
                    /> */}
                    <InputSearch
                      value={filter.search}
                      classFilter={MenuReportFilter}
                      placeHolder={translate("general.placeholder.search")}
                      onChange={handleChangeInputSearch}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="page-master__content-table">
              <StandardTable
                rowKey={nameof(list[0].id)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                spinning={loadingList}
                scroll={{ x: 1000 }}
              />
              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
              />
            </div>
          </div>
        </div>
      </Spin>

      {/* {visible && (
        <FileTemplateAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )} */}
    </>
  );
}
export default FileTemplateMaster;
