import React from "react";
import { Observable } from "rxjs";
import { Model, ModelFilter } from "react3l-common";
import { CKEditor } from "ckeditor4-react";

export interface FroalaEditorProps<
  T extends Model,
  TModelFilter extends ModelFilter
> {
  value?: string;

  label?: string;

  className?: string;

  isRequired?: boolean;

  editorConfig?: Record<string, any>;

  onChange?(value: string): void;

  placeholder?: string;

  getList?: (TModelFilter?: TModelFilter) => Observable<T[]>;
}

const Editor = React.forwardRef(
  (props: FroalaEditorProps<Model, ModelFilter>, ref: any) => {
    const { value, onChange, placeholder, label, isRequired, editorConfig } =
      props;

    const handleChange = React.useCallback(
      (event: any) => {
        if (typeof onChange === "function") {
          const value = event.editor.getData();
          onChange(value);
        }
      },
      [onChange]
    );

    const config = editorConfig
      ? editorConfig
      : {
          toolbar: [
            { name: "tools", items: ["Maximize"] },
            {
              name: "clipboard",
              items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"],
            },
            { name: "links", items: ["Link", "Unlink"] },
            { name: "document", items: ["Source"] },
            "/",
            {
              name: "basicstyles",
              items: [
                "Bold",
                "Italic",
                "Underline",
                "Strike",
                "-",
                "Subscript",
                "Superscript",
              ],
            },
            {
              name: "paragraph",
              items: [
                "NumberedList",
                "BulletedList",
                "-",
                "Outdent",
                "Indent",
                "Blockquote",
              ],
            },
            {
              name: "align",
              items: [
                "AlignLeft",
                "JustifyLeft",
                "JustifyCenter",
                "JustifyRight",
                "JustifyBlock",
              ],
            },
            "/",
            {
              name: "styles",
              items: ["Format", "-", "Font", "-", "FontSize"],
            },
            { name: "colors", items: ["TextColor", "BGColor"] },
            {
              name: "insert",
              items: ["Image", "Table", "HorizontalRule"],
            },
            "/",
          ],
          extraPlugins: "colorbutton,colordialog,font",
          removeButtons: "",
          placeholder: placeholder,
        };

    return (
      <>
        <div className="label-status d-flex">
          {label}
          {isRequired && <span className="text-danger">&nbsp;*</span>}
        </div>
        <CKEditor
          initData={value}
          onChange={(event) => {
            handleChange(event);
          }}
          editorUrl="https://cdnjs.cloudflare.com/ajax/libs/ckeditor/4.5.4/ckeditor.js"
          config={config}
        />
      </>
    );
  }
);

export default Editor;
