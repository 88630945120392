import { AppUser } from "models/AppUser";
import { FileTemplate } from "models/FileTemplate";
import { Menu } from "models/Menu";
import { Site } from "models/Site";
import type { Moment } from "moment";
import { Model } from "react3l-common";
import {
  Field,
  MomentField,
  ObjectField,
  ObjectList,
} from "react3l-decorators";

export class MenuReport extends Model {
  @Field(Number)
  public id?: number;
  @Field(String)
  public code?: string;
  @Field(String)
  public name?: string;
  @Field(Number)
  public siteId?: number;
  @Field(Number)
  public menuId?: number;
  @MomentField()
  public updatedAt?: Moment;
  @Field(Number)
  public modifierId?: number;

  @ObjectField(Site)
  public site?: Site;
  @ObjectField(Menu)
  public Menu?: Menu;
  @ObjectField(AppUser)
  public modifier?: AppUser;
  @ObjectList(FileTemplate)
  public fileTemplates?: FileTemplate[];
}
