import { LoginConfiguration } from "models/LoginConfiguration/LoginConfiguration";
import { Model } from "react3l-common";
import { Field, ObjectField } from "react3l-decorators";

export class LoginConfigurationContent extends Model {
  @Field(Number)
  public id?: number;

  @Field(String)
  public code?: string;

  @Field(String)
  public name?: string;

  @Field(String)
  public value?: string;

  @Field(Number)
  public loginConfigurationId?: number;

  @ObjectField(LoginConfiguration)
  public loginConfiguration?: LoginConfiguration;
}
