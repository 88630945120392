/* begin general import */
import OverflowMenuHorizontal24 from "@carbon/icons-react/es/overflow-menu--horizontal/24";
import { Dropdown, Menu, Switch } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { Site, SiteFilter } from "models/Site";
import { SiteType } from "models/SiteType";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  LayoutCell,
  LayoutHeader,
  OneLineText,
  Pagination,
  StandardTable,
  TagFilter,
} from "react3l-ui-library";
import InputSearch from "react3l-ui-library/build/components/Input/InputSearch";
/* end filter import */
/* begin individual import */
import { siteRepository } from "repositories/site-repository";
import appMessageService from "services/common-services/app-message-service";
import { detailService } from "services/page-services/detail-service";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import SiteDetailDrawer from "../SiteDetail/SiteDetailDrawer";
import SiteAdvanceFilter from "./SiteAdvanceFilter";
/* end individual import */

function SiteMaster() {
  const [translate] = useTranslation();

  const [modelFilter, dispatch] = queryStringService.useQueryString(
    SiteFilter,
    { skip: 0, take: 10 }
  );

  const [visible, setVisible] = React.useState<boolean>(false);

  const {
    value: filter,
    handleChangeInputSearch,
    handleChangeAllFilter,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleLoadList } = listService.useList(
    siteRepository.list,
    siteRepository.count,
    filter,
    dispatch
  );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    model,
    dispatch: dispatchModal,
    isOpenDetailModal,
    handleOpenDetailModal,
    handleCloseDetailModal,
    handleSaveModel,
    loadingModel,
    handleChangeSingleField,
    handleChangeSelectField,
  } = detailService.useDetailModal(
    Site,
    siteRepository.get,
    siteRepository.save,
    handleLoadList
  );
  const { notifyUpdateItemSuccess, notifyUpdateItemError } =
    appMessageService.useCRUDMessage();

  const handleChangeIsDisplay = React.useCallback(
    (site) => {
      return (value: boolean) => {
        site.isDisplay = value;
        siteRepository.update(site).subscribe(
          (res) => {
            notifyUpdateItemSuccess();
            handleLoadList();
          },
          (err) => {
            notifyUpdateItemError();
          }
        );
      };
    },
    [handleLoadList, notifyUpdateItemError, notifyUpdateItemSuccess]
  );

  const menuAction = React.useCallback(
    (id: number, site: Site) => (
      <Menu>
        <Menu.Item key="1">
          <div
            className="ant-action-menu"
            onClick={() => handleOpenDetailModal(id)}
          >
            {translate("general.actions.view")}
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div
            className="ant-action-menu"
            onClick={() => handleOpenDetailModal(id)}
          >
            {translate("general.actions.edit")}
          </div>
        </Menu.Item>
      </Menu>
    ),
    [handleOpenDetailModal, translate]
  );

  const columns: ColumnProps<Site>[] = useMemo(
    () => [
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "code"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("sites.code")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<Site, SiteFilter>(
          filter,
          nameof(list[0].code)
        ),
        render(...params: [string, Site, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "name"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("sites.name")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<Site, SiteFilter>(
          filter,
          nameof(list[0].name)
        ),
        render(...params: [string, Site, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "siteType"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("sites.siteType")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].siteType),
        dataIndex: nameof(list[0].siteType),
        sorter: true,
        sortOrder: getAntOrderType<Site, SiteFilter>(
          filter,
          nameof(list[0].siteType)
        ),
        render(...params: [SiteType, Site, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]?.name} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "description"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("sites.description")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].description),
        dataIndex: nameof(list[0].description),
        sorter: true,
        sortOrder: getAntOrderType<Site, SiteFilter>(
          filter,
          nameof(list[0].description)
        ),
        render(...params: [string, Site, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "colorCode"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("sites.colorCode")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].colorCode),
        dataIndex: nameof(list[0].colorCode),
        sorter: true,
        sortOrder: getAntOrderType<Site, SiteFilter>(
          filter,
          nameof(list[0].colorCode)
        ),
        width: 150,
        render(...params: [string, Site, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "isDisplay"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("sites.isDisplay")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        width: 130,
        key: nameof(list[0].isDisplay),
        dataIndex: nameof(list[0].isDisplay),
        sorter: true,
        sortOrder: getAntOrderType<Site, SiteFilter>(
          filter,
          nameof(list[0].isDisplay)
        ),
        render(...params: [boolean, Site, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              {params[1].code !== "/landing-page" ? (
                <Switch
                  onChange={handleChangeIsDisplay(params[1])}
                  defaultChecked={params[0]}
                />
              ) : (
                <Switch defaultChecked={params[0]} disabled={true} />
              )}
            </LayoutCell>
          );
        },
      },

      {
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, site: Site) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, site)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, list, filter, handleChangeIsDisplay, menuAction]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("sites.master.subHeader")}
          breadcrumbItems={[
            translate("sites.master.header"),
            translate("sites.master.subHeader"),
          ]}
        />
        <div className="page page-master m-t--lg m-l--sm m-r--xxl m-b--xxs">
          <div className="page-master__title p-l--sm p-t--sm p-r--sm p-b--lg">
            {translate("sites.master.title")}
          </div>
          <div className="page-master__content">
            <div className="page-master__tag-filter">
              <TagFilter
                value={filter}
                translate={translate}
                keyTranslate={" sites "}
                handleChangeFilter={handleChangeAllFilter}
                onClear={(value: any) => {
                  return 0;
                }}
              />
            </div>

            <InputSearch
              value={filter.search}
              classFilter={SiteFilter}
              placeHolder={translate("general.placeholder.search")}
              onChange={handleChangeInputSearch}
              animationInput={false}
            />
          </div>
          <div className="page-master__content-table">
            <StandardTable
              rowKey={nameof(list[0].id)}
              columns={columns}
              dataSource={list}
              isDragable={true}
              tableSize={"md"}
              onChange={handleTableChange}
              spinning={loadingList}
              scroll={{ x: 1000 }}
            />

            <Pagination
              skip={filter?.skip}
              take={filter?.take}
              total={count}
              onChange={handlePagination}
              //canChangePageSize={false}
            />
          </div>
        </div>
      </div>
      {visible && (
        <SiteAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}
      <SiteDetailDrawer
        model={model}
        visible={isOpenDetailModal}
        handleSave={handleSaveModel}
        handleCancel={handleCloseDetailModal}
        handleChangeSingleField={handleChangeSingleField}
        handleChangeSelectField={handleChangeSelectField}
        dispatch={dispatchModal}
        loading={loadingModel}
        visibleFooter={true}
      />
    </>
  );
}
export default SiteMaster;
